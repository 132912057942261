const COUNTRY_CUSTOM_DATA_ID = 3;

const INVITATION_CODE_CUSTOM_DATA_ID = 2;

const COUNTRIES = [ 
  "Argentina",
  "Bolivia",
  "Brasil",
  "United States",
  "Paraguay",
  "Uruguay"
]

const regExpEmail = /^[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?$/;

export {
    COUNTRY_CUSTOM_DATA_ID,
    INVITATION_CODE_CUSTOM_DATA_ID,
    COUNTRIES,
    regExpEmail
}
import React from 'react'
import warningYellow from '../../../assets/icons/warningYellow.svg'
import warningRed from '../../../assets/icons/warningRed.svg'
import warningGreen from '../../../assets/icons/warningGreen.svg'
import infoBlue from '../../../assets/icons/infoBlue.svg'

// type AuraIconProps = AuraIconPreset | AuraIconCustom

// interface AuraIconPreset {
//   size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
//   type: 'warning' | 'warning-green' | 'error' | 'info'
//   extraStyles?: CSSProperties
// }

// interface AuraIconCustom {
//   size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
//   type: 'custom'
//   svg: string
//   extraStyles?: CSSProperties
// }

const icons = {
  warning: warningYellow,
  'warning-green': warningGreen,
  error: warningRed,
  info: infoBlue
}

const sizes = {
  'x-small': '24px',
  small: '32px',
  medium: '48px',
  large: '64px',
  'x-large': '128px'
}

export const AuraIcon = (props) => {
  let src
  let width = sizes[props.size || 'medium']
  let extraStyles = props.extraStyles || {}

  // Types
  if (props.type !== 'custom') {
    src = icons[props.type]
  }
  if (props.type === 'custom') {
    src = props.svg
  }

  // Styles
  const AuraIconStyle = {
    padding: 0,
    margin: 0,
    aspectRatio: 1,
    width,
    ...extraStyles
  }
  return <img alt='' style={AuraIconStyle} src={src} />
}

import DrawerWrapper from "antd/lib/drawer";

export function loadCSSFromString(string) {

  const css = string,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  head.appendChild(style);

  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

}

export function getPremiumCssString(data) {


  let look_feel = data.look_and_feel.dark;
  let styleSheet;

  if (look_feel && look_feel.accounts) {
    styleSheet =

      `
            .premium-card{
              background: ${look_feel.accounts.card.background} !important;
              color:${look_feel.accounts.card.color} !important;
            }
            .premium-input{
              background: ${look_feel.accounts.inputs.background} !important;
              color:${look_feel.accounts.inputs.color} !important;
            }

            .welcome{              
              color: ${look_feel.accounts.buttons.color} !important;
            }

            .premium-button-action{
              background-color: ${look_feel.accounts.buttons.action.background} !important;
              color: ${look_feel.accounts.buttons.action.color} !important;
            }

            .premium-button-minor{
              background-color: ${look_feel.accounts.buttons.minor.background} !important;
              color: ${look_feel.accounts.buttons.minor.color} !important;
            }

            .premium-powered-by{
              background-color: ${look_feel.accounts.powerby.background} !important;
              color: ${look_feel.accounts.powerby.color} !important;
            }

            .premium-form-icon-fill{
              fill: ${look_feel.forms.icon} !important;
            }

            .premium-form-icon-stroke{
              stroke: ${look_feel.forms.icon} !important;
            }

            .premium-form-line{
              color: ${look_feel.forms.line} !important;
            } 

            `

  }

  return styleSheet

}

export function premiumCss() {

  if (document.querySelector('.ant-select-selector') !== null) {

    document.querySelector('.ant-select-selector').classList.add('premium-input');
  }

}
import React from 'react'

import { AiOutlineInfoCircle } from 'react-icons/ai'

// COMPONENTE DE TOOLTIP CON INFO
const FieldInfo = props => {

  if ( !props.messages || !props.messages[ props.locale ] ) return null

    return (
      <div className="info ">
        <div className="icon"> <AiOutlineInfoCircle /> </div>
        <div className="tooltip"> { props.messages && props.messages[ props.locale ] } </div>
      </div>
    )
  }

export default FieldInfo
import React, { useEffect } from 'react';
import amplitude from 'amplitude-js'
import env from '../environment/environment'
import Main from '../routes/main/Main'
import { BrowserRouter } from 'react-router-dom';
import '../style/global.css'
import 'toastr/build/toastr.min.css'
import { MainContextProvider } from '../context/MainContext';

const App = ( ) => {

   useEffect( () => {

      amplitude.getInstance().init( env.amplitude_key, null, {
         includeReferrer: true, 
         includeUtm: true, 
         includeGclid: true, 
         unsetParamsReferrerOnNewSession: true
     } )

   }, [])

  return (
     <div className="App">
         <BrowserRouter>
            <MainContextProvider>
               <Main />
            </MainContextProvider>
         </BrowserRouter>         
     </div>
  )
}

export default App

import React from 'react'
import { AuraPopUpAlert } from '../AuraPopUpAlert'
import { Trans } from 'react-i18next'

export const SuspendedPopup = ({ t, show, setShow }) => {

  return (
    <AuraPopUpAlert
    title={t("Suspended account")}
      type={'ERROR'}
      onClose={() => setShow(false)}
      visible={show != null && show !== false}
    >
       <div style={{ fontSize: "1em", marginTop: '16px', textAlign: "center" }}>
          <p style={{fontWeight: "bold"}}>
            {t('Oops! This account has been suspended')}.
          </p>
          <p>
            {t('For further information, please contact')}
            {/* <Trans i18nKey='suspended.contactus' values={{email: "administracion@auravant.com"}}>
              Contact <strong>administracion@auravant.com</strong> for further information.
            </Trans> */}
          </p>
          <a href='mailto:administracion@auravant.com' target='_blank' style={{fontWeight: "bold"}}>administracion@auravant.com</a>
        </div>
    </AuraPopUpAlert>
  )
}

import env from '../environment/environment';
import Api from './Api';

const workspaceService = {

  getData: (jsonData) => {

    return Api.get(`/desktops/${jsonData.space}/externaldata`)

  },
  getIcon: space => {

    return Api.get(`/desktops/thumb?s=${space}`)
  },
  getDefaultTenant: () => {
    
    return Api.get(`/workspaces/tenant`)
  }

}

export default workspaceService

import { React } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Login from '../main/login/Login'
import Register from '../main/register/Register'
import NewPassword from '../main/newPassword/NewPassword';
import Recovery from '../main/recovery/Recovery'

import Footer from './Footer'
import { SuspendedAccount } from './EmailVerification/SuspendedAccount';
import { EmailSent } from './EmailVerification/EmailSent';
import { EmailValidation } from './EmailVerification';
import { EmailVerificationResult } from './EmailVerification/Result';

const Card = props => {

    const location = useLocation()

    return (
        <div className="card-container">
            <div className="card premium-card">
                <Routes>
                    <Route path='/login' element={ <Login workspace={ props.workspace } locale={ props.locale } deeplink={ props.deeplink } bundle={ props.bundle } startFeature={ props.startFeature } /> } /> 
                    <Route path='/checkout' element={ <Login checkout workspace={ props.workspace } locale={ props.locale } deeplink={ props.deeplink } /> } /> 
                    <Route path='/register' element={ <Register workspace={ props.workspace } token={ props.token } locale={ props.locale } deeplink={ props.deeplink } bundle={ props.bundle } initialUser={ props.initialUser } setLocale={ props.setLocale } /> } />  
                    <Route path='/checkout-register' element={ <Register checkout workspace={ props.workspace } locale={ props.locale } setLocale={ props.setLocale } /> } />  
                    <Route path='/email-validation' element={ <EmailValidation /> }>
                        <Route path='suspended' element={ <SuspendedAccount /> } />  
                        <Route path='sent' element={ <EmailSent email="string@gmail.com" /> } />  
                        <Route path='result' element={ <EmailVerificationResult email="string@gmail.com" /> } />  
                    </Route>
                    <Route path='/email-sent' element={ <EmailSent email="string@gmail.com" /> } />  
                    <Route path='/recovery' element={ <Recovery workspace={ props.workspace } locale={ props.locale } /> } />  
                    <Route path='/newpassword' element={ <NewPassword workspace={ props.workspace } token={ props.token } locale={ props.locale } /> } />  
                    <Route path='*' element={ <Navigate to={`/login${location.search}`} /> } /> 
                </Routes>

                <Footer locale={ props.locale } setLocale={ props.setLocale } powerbyauravant={ props.workspace.data?.premium } />
            </div>
        </div>
        )
}

export default Card
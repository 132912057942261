import { buildQueryString } from '../constants/fx';
import env from '../environment/environment';

// ACCOUNTS URL
// PROTOCOL://accounts.ACCOUNTS_ENVIRONMENT_PREFIX.TENANT/login?s=SUBDOMAIN
// EJs: 
// 1 - https://accounts.auravant.com
// 2 - https://accounts.auravant-corp.com/login?s=asd
// 3 - http://accounts.testing.auravant.com
// 4 - http://accounts.testing.auravant-corp.com
// 5 - http://accounts.testing.auravant-corp.com?s=asd


const tenancyService = {
    // AURAVIEW URL
    // PROTOCOL://SUBDOMAIN.AURAVIEW_ENVIRONMENT_PREFIX.TENANT
    // EJs: 
    // 1 - https://auraview.auravant.com
    // 2 - https://asd.auravant.com
    // 3 - http://testing.auravant.com
    // 4 - http://testing.auravant-corp.com
    // 5 - http://asd.testing.auravant-corp.com
    redirectToAuraview: ( subdomain, deeplink, startFeature ) => {

        if ( window.location.hostname.indexOf( env.accounts_prefix_key ) > -1 ){

            const protocol = window.location.protocol
            const url_subdomain = subdomain ? ( subdomain + '.' ) : ( env.base_platform_url_subdomain ? ( env.base_platform_url_subdomain + '.' ) : 'auraview' )
            const url_prefix = env.base_platform_url_prefix ? ( env.base_platform_url_prefix + '.' ) : ''
            const tenant = env.base_accounts_url_prefix ? window.location.hostname.replace( env.accounts_prefix_key + '.' + env.base_accounts_url_prefix + ".", "" ) : window.location.hostname.replace( env.accounts_prefix_key + '.', '' );
            const platform_params = ( deeplink ? parsePlatformDeeplink( deeplink ) : ( startFeature && startFeature === 'unsubscription' ? 'preferences#subscriptions=1' : '' ) )
            const utm_querys = buildQueryString()
            
            window.location = `${ protocol }//${ url_subdomain }${ url_prefix }${ tenant }/${ platform_params }` + (utm_querys && utm_querys.length ? `?${utm_querys}` : '' )
        }

      },
      getAPICurrentBaseURL: () => {
    // API URL
    // PROTOCOL://api.API_ENVIRONMENT_PREFIX.TENANT/api
    // EJs: 
    // 1 - https://accounts.auravant.com
    // 2 - https://accounts.auravant-corp.com/login?s=asd
    // 3 - http://accounts.testing.auravant.com
    // 4 - http://accounts.testing.auravant-corp.com
    // 5 - http://accounts.testing.auravant.com/login?s=asd
    
        const hostname = window.location.hostname.replace( env.base_accounts_url_prefix ? ( env.base_accounts_url_prefix + "." ) : "", "" ).replace( env.accounts_prefix_key + ".", "" )
        const api_prefix = env.api_prefix ? ( env.api_prefix + '.' ) : ''

        return `${ window.location.protocol }//${ env.api_url_key }.${ api_prefix }${ hostname }/api`
    
      },
      getCurrentTenant: () => {

        if ( window.location.hostname.indexOf( env.accounts_prefix_key ) > -1 ){

            const tenant = env.base_accounts_url_prefix ? window.location.hostname.replace( env.accounts_prefix_key + '.' + env.base_accounts_url_prefix + ".", "" ) : window.location.hostname.replace( env.accounts_prefix_key + '.', '' );
            
            return tenant
        }

      },
      reloadWithCorrenctTenant: tenant => {

        const path = window.location.pathname
        const params = window.location.search
        const accounts_prefix = env.base_accounts_url_prefix ? ( env.base_accounts_url_prefix + '.' ) : ''

        window.location = `${ window.location.protocol }//${ env.accounts_prefix_key }.${ accounts_prefix }${ tenant }${ path }${ params }`

      }
}

export default tenancyService

function parsePlatformDeeplink( deeplink ){

    if ( !deeplink ) return ''

    var feature;
    var deepLinkParams = []
    var utmParams = [];
    if ( deeplink.indexOf( 'aura-feature' ) > -1 ){
        feature = deeplink.split( 'aura-feature' )[ 1 ].split( '_' )[ 1 ]
    }

    if ( deeplink.indexOf( 'aura-params_' ) > -1 ){
        deeplink
            .split( 'aura-params_' )[ 1 ]
            .split( ',' )
            .map( param => {

            if ( param.split( "aura-key=" )[ 1 ] && param.split( "aura-key=" )[ 1 ].split( "aura-value=" )[ 0 ] && param.split( "aura-value=" )[ 1 ] ){

                const key = param.split( "aura-key=" )[ 1 ].split( "aura-value=" )[ 0 ]
                const value = param.split( "aura-value=" )[ 1 ]

                if ( key.indexOf( 'utm_' ) >  -1 ){
                utmParams.push( `${ key + "=" + value }` )
                } else {
                deepLinkParams.push( `${ key + "=" + value }` )
                }
            }

            } )
    }

    return `${ feature }#${ deepLinkParams.join( '&' ) }?${ utmParams.join( '&' ) }`

}
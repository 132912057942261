import React, { createContext, useEffect, useState } from 'react'

export const MainContext = createContext({
  email: '',
  setEmail: () => {},
  emailValidationClosable: false,
  setEmailValidationClosable: () => {},
  emailValidationTitle: '',
  setEmailValidationTitle: () => {}
})

export const MainContextProvider = ({ children }) => {
  const [email, setEmail] = useState('')
  const [emailValidationClosable, setEmailValidationClosable] = useState(false)
  const [emailValidationTitle, setEmailValidationTitle] = useState(false)

  useEffect(() => {
    console.log(email)
  }, [email])

  return (
    <MainContext.Provider
      value={{
        email,
        setEmail,
        emailValidationClosable,
        setEmailValidationClosable,
        emailValidationTitle,
        setEmailValidationTitle
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

import Api from './Api';

const recoveryService = {

  recover: ( jsonData ) => {

    return Api.post('/olvido_psw', `mail=${ jsonData.email }&g-recaptcha-response=${ jsonData.captcha }${ jsonData.space ? '&desktop=' + jsonData.space : '' }`, {
      withCredentials: true
    } )
  },

  newPassword: ( jsonData ) => {

    return Api.post( '/olvido_psw2', `username=${ jsonData.email }&psw=${ jsonData.password }&psw2=${ jsonData.confirmPassword }&token=${ jsonData.token }&g-recaptcha-response=${ jsonData.captcha }${ jsonData.space ? '&desktop=' + jsonData.space : '' }`, {
      withCredentials: true
    })
  }

}

export default recoveryService

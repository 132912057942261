import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next'

const TelefonicaFooter = ({ t }) => {

    useEffect( () => {

        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.src = "https://cdn.cookielaw.org/consent/bfe31d7c-4362-4df1-ab34-faa17e8cee8f-test/OtAutoBlock.js"
        document.head.appendChild(script1);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.setAttribute( 'data-domain-script', 'bfe31d7c-4362-4df1-ab34-faa17e8cee8f-test' )
        script2.setAttribute( 'charset', 'UTF-8' )
        script2.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"

        document.head.appendChild(script2);

        window.OptanonWrapper = () => {

            window.dataLayer.push({ event: 'OneTrustGroupsUpdated',  properties: { place: 'accounts' } });

        }

    }, [] )

    return (
        <div className="links">
            <div className="item" onClick={ () => { window.open( 'https://aiofthings.telefonicatech.com/aviso-legal', '_blank' ) } }>{ t( 'Legal Notice' ) }</div>
            |
            <div className="item" onClick={ () => { window.open( 'https://aiofthings.telefonicatech.com/legal-privacidad', '_blank' ) } }>{ t( 'Privacy Policy' ) }</div>
            |
            <div className="item" onClick={ () => { window.open( 'https://telefonicatech.com/cookies', '_blank' ) } }>{ t( 'Cookies' ) }</div>
            |
            <div className="item ot-sdk-show-settings" ></div>
        </div>
    )

}

export default withNamespaces()( TelefonicaFooter )
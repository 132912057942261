import { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../../../context/MainContext'
import { getQueryParams } from '../../../../constants/fx'
import { useLocation } from 'react-router-dom'
import { EmailValidationSuccessful } from './Successful'
import { EmailValidationError } from './Error'

const STATES = {
  success: 'SUCCESS',
  error: 'ERROR'
}

export const EmailVerificationResult = () => {
  let location = useLocation()
  const [result, setResult] = useState(STATES.success)

  const { setEmailValidationClosable, setEmailValidationTitle } =
    useContext(MainContext)

  useEffect(() => {
    let query = getQueryParams(location.search)
    if (query.get('email_verification') === 'OK') {
      setResult(STATES.success)
    } else {
      setResult(STATES.error)
    }
    setEmailValidationClosable(false)
    setEmailValidationTitle('Validación de e-mail')
  }, [])

  return result === STATES.success ? (
    <EmailValidationSuccessful />
  ) : (
    <EmailValidationError />
  )
}

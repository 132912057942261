import { React } from 'react'
import { withNamespaces } from 'react-i18next';
import './Title.css'

import tenancyService from '../../services/tenancy'

const Title = props => {

    const cropchainMigrationWarning = props?.workspace?.subdomain === 'cropchain'

    return (
        <>
            <div className={props.workspace && props.workspace.data && !props.workspace.data.premium && props.workspace.subdomain !== 'my' ? "title workspace" : "title"}>
                {props.workspace && props.workspace.data && !props.workspace.data.premium && <img src="/assets/logo.svg" alt="logo auravant" /> || !props.workspace.subdomain && <img src="/assets/logo.svg" alt="logo auravant" />}
                {props.workspace.subdomain && props.workspace.subdomain !== 'my' && <img src={props.workspace.data.logo_url ? props.workspace.data.logo_url : tenancyService.getAPICurrentBaseURL() + '/desktops/logo?s=' + props.workspace.subdomain} />}
            </div>
            {
                cropchainMigrationWarning &&
                <div className="cropchain-migration-warning">
                    {props.t('A partir de 1 de outubro CropChain deixará de estar disponível. Caso deseje manter sua conta e o histórico completo dos seus dados cadastrados, ingresse e migre gratuitamente à Auravant')} <a href="https://www.auravant.com/pt/cropchain/" target="_blank">{props.t('mais info')}</a>
                </div>
            }
        </>
    )
}

export default withNamespaces()(Title)
import React from 'react'
import dompurify from 'dompurify'
import { resources } from '../../../../i18n/i18n'

import { COUNTRY_CUSTOM_DATA_ID } from '../../../../constants/constants'

import { Select } from 'antd';
import { parseCountryFromLanguage } from '../../../../constants/fx';

const { Option } = Select;

// COMPONENTE DE DATOS CUSTOM SEGUN ID
// X - INPUT
// COUNTRY_CUSTOM_DATA_ID - SELECT DE PAISES ( GDM )
const CustomData = (props) => {

  let validLanguage = Object.keys(resources).find(dato => dato.split("_")[0] === navigator.language.split("-")[0])
  let initLanguage = validLanguage ? parseCountryFromLanguage(validLanguage) : 'Argentina'

  if (props.id !== COUNTRY_CUSTOM_DATA_ID) {

    if (props.type && props.type === "list") {
      const opciones = props.items.sort()
      return (
        <div className={props.required ? "field-wrapper select premium-input-minor required" : "field-wrapper select premium-input-minor"} >
          <Select
            showSearch
            className="field-wrapper select premium-input" popupClassName="premium-input" defaultValue="" size="large" onChange={item => { props.handleChangeValue(props.name, { id: props.id, value: item }) }}
            placeholder={props.placeholder} >
            <Option value="">  </Option>
            {
              opciones.map(item =>
                <Option value={item}>{item}</Option>
              )
            }
          </Select>
          <span className="placeholder premium-input">{props.placeholder}</span>
        </div >
      )
    }
    else {
      return (
        <div className={props.required ? "field-wrapper input required" : "field-wrapper input   "}>
          <input id={props.id} step={props.step ? props.step : ""} minlength={props.min_length ? props.min_length : ""} maxlength={props.max_length ? props.max_length : ""} max={props.max ? props.max : ""} min={props.min ? props.min : ""} value={props.formdata[props.name] && props.formdata[props.name].value} disabled={props.formdata[props.name] && props.formdata[props.name].disabled} onChange={ev => { props.handleChangeValue(props.name, { id: props.id, value: props.type === 'string' ? ev.target.value : Number(ev.target.value) }) }} type={props.type === 'string' ? 'text' : 'number'} className="form-control premium-input" placeholder={props.placeholder} name={props.name} />
          <span className="placeholder premium-input">{props.t(props.name)}</span>
        </div>
      )
    }
  } else {

    if (props.query.get('cd' + COUNTRY_CUSTOM_DATA_ID)) initLanguage = dompurify.sanitize(props.query.get('cd' + COUNTRY_CUSTOM_DATA_ID))

    return (
      <div className="field-wrapper select  premium-input-minor" >
        <Select className="premium-input" defaultValue={initLanguage} size="large" onChange={pais => { props.handleChangeValue(props.name, { id: props.id, value: pais }) }}  >
          <Option value="Argentina" > {props.t('Argentina')} </Option>
          <Option value="Bolivia" > {props.t('Bolivia')} </Option>
          <Option value="Brasil" > {props.t('Brasil')} </Option>
          <Option value="United States" > {props.t('Estados Unidos')} </Option>
          <Option value="Paraguay" > {props.t('Paraguay')} </Option>
          <Option value="Uruguay" > {props.t('Uruguay')} </Option>
        </Select>
      </div>
    )
  }

}

export default CustomData
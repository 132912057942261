import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Card from '../components/Card'
import workspaceService from '../../services/Workspace'

import './main.css'
import { loadCSSFromString } from '../../services/PremiumLogin';
import { getPremiumCssString } from '../../services/PremiumLogin';

import '../../i18n/i18n'

import { resources } from '../../i18n/i18n'

import i18n from '../../i18n/i18n';
import { withNamespaces } from 'react-i18next';

import backgroundImage from '../../images/LoginA.jpg'
import registerService from '../../services/Register';
import tenancyService from '../../services/tenancy';
import TelefonicaFooter from '../components/TelefonicaFooter';
// import { EmailVerificationPopUp } from '../components/EmailVerificationPopUp';
import { SuspendedPopup } from '../components/SuspendedPopup';
import { getQueryParams } from '../../constants/fx';

const getRandomElement = array => {

  return array[Math.floor(Math.random() * array.length)]
}

const setFavicon = (space, logo) => {

  if (space) {

    if (logo) {
      document.querySelectorAll("link[rel~='icon']").forEach((e, i) => {
        e.setAttribute("type", "image/x-icon");
        e.href = logo;
      });
    }
    else {
      workspaceService.getIcon(space)
        .then(res => {

          var svgString = res.data
          var decoded = unescape(encodeURIComponent(svgString));
          var base64 = btoa(decoded);
          var imgSource = `data:image/svg+xml;base64,${base64}`;

          document.querySelectorAll("link[rel~='icon']").forEach((e, i) => {
            e.setAttribute("type", "image/x-icon");

            e.href = imgSource;
          });

        })
    }
  } else {

    document.head.innerHTML = `
                              <link rel="apple-touch-icon" sizes="57x57" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="60x60" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="72x72" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="76x76" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="114x114" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="120x120" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="144x144" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="152x152" href="./favicon/thumb.svg">
                              <link rel="apple-touch-icon" sizes="180x180" href="./favicon/thumb.svg">
                              <link rel="icon" type="image/png" sizes="192x192"  href="./favicon/thumb.svg">
                              <link rel="icon" type="image/png" sizes="32x32" href="./thumb.svg">
                              <link rel="icon" type="image/png" sizes="96x96" href="./thumb.svg">
                              <link rel="icon" type="image/png" sizes="16x16" href="./thumb.svg">
                              ` + document.head.innerHTML

  }


}




const Main = ({ t }) => {

  const [loading, setLoading] = useState(true)
  const [locale, setLocale] = useState(i18n.language)
  const [workspace, setWorkspace] = useState(null)
  const [background, setBackground] = useState(null)
  const [token, setToken] = useState(null)
  const [bundle, setBundle] = useState(null)
  const [deeplink, setDeeplink] = useState(null)
  const [startFeature, setStartFeature] = useState(null)
  const [initialUser, setInitialUser] = useState(null)
  const [emailValidationCb, setEmailValidationCb] = useState(null)
  const [suspended, setSuspended] = useState(false)
  const [styles, setStyles] = useState({});

  let location = useLocation()
  let navigate = useNavigate()

  // const reducer = ( acc, cur ) =>{
  //     return acc.concat( cur.split( "_" )[0] )
  // }

  useLayoutEffect(() => {
    window.addEventListener('message', evt => {
      if (evt.data.type === 'suspended_user') {
        setSuspended(true)
      }
      if (evt.data.type === 'EMAIL_VERIF_PENDING') {
        navigate("/email-validation/suspended")
      }
    })
  }, [])


  useEffect(() => {

    let query = getQueryParams(location.search);
    const initPromises = [];

    //Se obtiene el lenguaje de la URL, y en su 
    //defecto se obtiene el lenguaje del navegador
    let nav_language = Object.keys(resources).find(dato => dato === navigator.language.replace("-", "_"));

    if (!nav_language) {
      if (navigator.language.split("-")[0] === "es") {
        if (new Date().getTimezoneOffset() > 0)
          nav_language = "es_AR";
        else
          nav_language = "es_ES";
      }
      else
        nav_language = Object.keys(resources).find(dato => dato.split("_")[0] === navigator.language.split("-")[0]);
    }


    if (Object.keys(resources).includes(query.get('locale'))) {
      setLocale(query.get('locale'));
      i18n.changeLanguage(query.get('locale'));
    } else {
      if (nav_language) {
        setLocale(nav_language);
        i18n.changeLanguage(nav_language);
      } else {
        setLocale("en_US");
        i18n.changeLanguage("en_US");
      }
    }

    setWorkspace({ subdomain: query.get('s') })
    setBundle(query.get('bun'))
    setStartFeature(query.get('f'))
    setToken(query.get('t'))
    setDeeplink(query.get('dl'))

    if (query.get('t')) {
      initPromises.push(
        registerService.getUserDataByToken(query.get('t')).then(data => {
          if (Object.keys(data.data.data).length) setInitialUser(data.data.data)
        })
      )
    }

    if (query.get('s')) {

      if (query.get('s') === 'aca') {
        window.location.href = window.location.href.replace('s=aca', 's=plataforma')
      }

      initPromises.push(
        workspaceService.getData({
          space: query.get('s')
        }).then(data => {

          // HANDLER POR SI NO ESTOY EN EL TENANT CORRECTO DEEL ESPACIO
          const currentTenant = tenancyService.getCurrentTenant()
          if (
            data.data.tenant &&
            currentTenant &&
            data.data.tenant !== currentTenant
          ) {

            tenancyService.reloadWithCorrenctTenant(data.data.tenant)
            return
          }

          if (data.data) {

            setWorkspace({ subdomain: query.get('s'), data: data.data })

            if (
              data.data &&
              data.data.look_and_feel &&
              data.data.look_and_feel.dark &&
              data.data.look_and_feel.dark.accounts
            ) {

              if (data.data.look_and_feel.dark.accounts.background) {

                if (
                  data.data.look_and_feel.dark.accounts.background.images &&
                  data.data.look_and_feel.dark.accounts.background.images.length
                ) {
                  setBackground(`url(${getRandomElement(data.data.look_and_feel.dark.accounts.background.images)})`)
                } else if (data.data.look_and_feel.dark.accounts.background.color) {
                  setBackground(data.data.look_and_feel.dark.accounts.background.color)
                }

              }

              const styleSheet = getPremiumCssString(data.data)
              if (styleSheet) loadCSSFromString(styleSheet)
            }

          }

          if (data.data.premium) {
            setFavicon(query.get('s'), data.data.logo_url)
            document.title = capitalize(query.get('s'))
          } else {
            setFavicon()
            document.title = 'Auravant'
          }

        })
      )
    } else {

      //ESTAMOS EN DOMINIO CUSTOM, SINO TIENE LA S, HACER ALGO
      if (window.location.host.toLowerCase().indexOf("auravant.com") < 0) {
        workspaceService.getDefaultTenant()
          .then(data => {
            if (data.data.data.default_subdomain) {
              if (window.location.href.indexOf("?") >= 0)
                window.location.href = "&s=" + data.data.data.default_subdomain
              else
                window.location.href = "?s=" + data.data.data.default_subdomain
            }

          })
        return
      }


      Promise.all(initPromises).then(res => {
        setLoading(false)
        setBackground(backgroundImage)

        setFavicon()
        document.title = 'Auravant'
      })

    }

    if (query.get('email_verification')) {
      navigate(`/email-validation/result${location.search}`)
    }
    if (query.get('suspended') === "true") {
      setSuspended(true)
    }

    Promise.all(initPromises).then(res => {
      setLoading(false)
    })

  }, [])

  const handleCookiesConfig = () => {
    window.OptanonWrapper()
  }

  if (loading) {
    return (
      <div className="loading">
        <div className="icon">
          <img src="/assets/loading.svg" alt="Loading"></img>
        </div>
        <div className="tag"> ..Cargando.. </div>
      </div>
    )
  } else {

    return (
      <div className="mainContainer " style={{ background: background }}>

        <div className="overlay">
          <Card
            locale={locale}
            setLocale={setLocale}
            workspace={workspace}
            token={token}
            bundle={bundle}
            deeplink={deeplink}
            startFeature={startFeature}
            initialUser={initialUser}
          />

        </div>

        {
          workspace &&
          workspace.data &&
          workspace.data.workspace === 139 &&
          <TelefonicaFooter />
        }
        {/* <EmailVerificationPopUp t={t} /> */}
        <SuspendedPopup t={t} show={suspended} />
      </div>
    )

  }

}

function capitalize(str) {
  return str
    .split(' ')
    .map(s => s.length > 1 ? s.charAt(0).toUpperCase() + s.slice(1) : s)
    .join(' ')
}

export default withNamespaces()(Main)

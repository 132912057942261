import React, {
  useEffect,
  useRef,
} from "react";
import { AuraIcon } from "../AuraIcon";
import "./index.css";
import actionQuit from "../../../assets/icons/actionQuit.svg";
import { AuraButton } from "../AuraButton";

// interface buttonOptionsInterface {
//   closeOnClick?: boolean
// }

// interface PopUpProps {
//   title?: string;
//   children: ReactNode;
//   cancelButtonText?: string;
//   okButtonText?: string;
//   visible: boolean;
//   onOk?: () => void;
//   onCancel?: () => void;
//   onClose?: () => void;
//   onOpen?: () => void;
//   mainExtraStyles?: CSSProperties;
//   okButtonColor?: string
//   buttonOptions?: buttonOptionsInterface
//   //   cancelOnBackdropClick: boolean;
// }

const AuraPopUp = ({
  title,
  children,
  cancelButtonText,
  okButtonText,
  visible,
  onOk,
  onOpen,
  onClose,
  onCancel,
  mainExtraStyles,
  okButtonColor,
  buttonOptions
}) => {

  const button_closeOnClick = typeof buttonOptions?.closeOnClick !== "undefined" ? buttonOptions?.closeOnClick : true

  const ref = useRef()

  useEffect(() => {
    const dialog = ref.current;
    if (!dialog?.open && !!visible) {
      typeof onOpen !== "undefined" && onOpen();
      dialog?.showModal();
    }
    if (dialog?.open && !visible) {
      typeof onClose !== "undefined" && onClose();
      dialog?.close();
    }
  }, [visible, onClose, onOpen]);

  function handleCancel() {
    const dialog = ref.current;
    typeof onCancel !== "undefined" && onCancel();
    if(button_closeOnClick){
      dialog.close();
    }
  }

  function handleOk() {
    const dialog = ref.current;
    typeof onOk !== "undefined" && onOk();
    typeof onClose !== "undefined" && onClose();
    if(button_closeOnClick){
      dialog.close();
    }
  }

  const okButtonStyles = {
    backgroundColor: okButtonColor || ""
  }

  return isElementSupported("dialog") ? (
    <dialog ref={ref}>
      <header>
        <h1>{title}</h1>
        <button
        className="pressableElement"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            typeof onClose !== "undefined" && onClose();
            handleCancel()
          }}
        >
          <AuraIcon type="custom" size="x-small" svg={actionQuit} />
        </button>
      </header>
      <main style={mainExtraStyles || {}}>{children}</main>
      {(cancelButtonText || okButtonText) && (<footer>
        {cancelButtonText && (
          <AuraButton className="pressableElement" onClick={() => handleCancel()} styleType="minorAction">
            {cancelButtonText}
          </AuraButton>
        )}
        {okButtonText && (
          <AuraButton className="pressableElement" onClick={() => handleOk()} styleType="primary" style={okButtonStyles}>
            {okButtonText}
          </AuraButton>
        )}
      </footer>)}
    </dialog>
  ) : (
    <section
      style={{
        position: "fixed",
        display: visible ? "flex" : "none",
        top: "0",
        zIndex: "10",
        background: "#00000040",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => {
        typeof onCancel !== "undefined" && onCancel();
      }}
    >
      <article
        style={{
          background: "#FFFFFF",
          maxWidth: "286px",
          width: "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <header
          style={{
            borderBottom: "1px solid #afaeae",
            display: "flex",
            padding: "10px 20px",
          }}
        >
          <h1 style={{ color: "black", margin: "auto", fontWeight: 600 }}>
            {title}
          </h1>
          <button
            className="popup__html__close__icon pressableElement"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              handleCancel()
              // typeof onCancel !== "undefined" && onCancel();
            }}
          >
            <AuraIcon type="custom" size="x-small" svg={actionQuit} />
          </button>
        </header>

        <section
          style={{
            color: "black",
            minHeight: "158px",
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            fontSize: "14px",
          }}
        >
          {children}
        </section>

        <footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "24px 18px 12px",
          }}
        >
          <button
            style={{
              color: "#0D72B9",
              textDecoration: "underline",
              fontWeight: "600",
              paddingLeft: "8px",
            }}
            onClick={() => handleCancel()}
          >
            {cancelButtonText}
          </button>

          <button
            onClick={() => handleOk()}
            style={{
              background: "#35BA71",
              color: "#FFFFFF",
              maxWidth: "126px",
              width: "100%",
              paddingTop: "8px",
              paddingBottom: "8px",
              borderRadius: "5px",
            }}
          >
            {okButtonText}
          </button>
        </footer>
      </article>
    </section>
  );
};

export default AuraPopUp;

function isElementSupported(tag) {
  const element = document.createElement(tag);
  const repr = element.toString();

  let isValid = repr === "[object HTMLUnknownElement]" ? null : true;

  if (tag.includes("-")) {
    if (repr === "[object HTMLElement]") {
      isValid = false;
    }
  }

  return isValid;
}

import Api from './Api';

const termsAndConditionsService = {

  get: ( workspaceId ) => {

    return Api.get('/terms/v2?workspace_id=' + workspaceId )
  },

}

export default termsAndConditionsService

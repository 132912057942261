import React, { useState } from 'react';
import { Button } from 'antd';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Title from '../../components/Title'
import toastr from 'toastr'
import recoveryService from '../../../services/Recovery'
import amplitude from 'amplitude-js'
import './newPassword.css'

const handleError = (code, t) => {

  let msg = t('There was a mistake. Please try again in a few minutes')
  switch (code) {
    case 1:
      msg = t("The email is non-existent or the user never registered")
      break;
    case 2:
      msg = t("Passwords do not match. Try again")
      break;
    case 3:
      msg = t("The password does not respect the established format. Enter password from 6 to 20 characters")
      break;
    case 4:
      msg = t("There was a mistake. The token may not be valid")
      break;
    case 5:
      msg = t("The request expired. Request a new password change")
      window.setTimeout(function () { window.location.href = "/recovery" }, 2500)
      break;
    case 6:
      msg = t("Missing data")
      break;
    case 7:
      msg = t("General server error")
      break;
    case 8:
      msg = t("Invalid CAPTCHA. Try again")
      break;
    case 10:
      msg = t("User with pending invitation")
      break;
    default:

      break;
  }

  toastr.error(msg)

}

const NewPassword = props => {
  const [loading, setLoading] = useState(false)

  const newPassword = () => {

    const jsonData = {
      password: document.getElementById('new-password').value,
      confirmPassword: document.getElementById('new-confirm-password').value,
      space: props.workspace && props.workspace.subdomain,
      token: props.token
    }

    if (!jsonData.password || !jsonData.confirmPassword) {
      handleError(6, props.t)
      return
    }
    if (jsonData.password != jsonData.confirmPassword) {
      handleError(2, props.t)
      return
    }

    setLoading(true)

    window.grecaptcha.enterprise
      .ready(function () {
        window.grecaptcha.enterprise
          .execute(
            '6Lc9nQIkAAAAAF2jJBlcmJWiOxLzSlqyU0XQ0VLY',
            { action: 'login' })
          .then(token => {

            jsonData['captcha'] = token

            recoveryService.newPassword(jsonData)
              .then(res => {

                setLoading(false)
                amplitude.getInstance().logEvent('Password Recovery Finished');

                toastr.success(props.t('You updated your password successfully!'))

                document.querySelector('.newPasswordContainer .login a').click()

              })
              .catch(err => {
                setLoading(false)
                const code = err.response.data.code
                handleError(code, props.t)
              })
          });
      });

  }

  return (
    <div className="newPasswordContainer">

      <Title workspace={props.workspace} />

      <div className="form">

        <div className="field-wrapper input">
          <input id="new-password" name="username" type="password" className="form-control premium-input" placeholder=" " />
          <span>{props.t('New Password')}</span>
        </div>

        <div className="field-wrapper input">
          <input id="new-confirm-password" name="username" type="password" className="form-control premium-input" placeholder=" " />
          <span>{props.t('Confirm Password')}</span>
        </div>

      </div>
      <div className="actions">

        <Button type="primary" block size="large" onClick={newPassword} loading={loading} className="premiun-button-action">
          {props.t('Save new password')}
        </Button>

        <div className="login">
          {props.t('Don\'t need this? Please')} <Link to={"/login"}>{props.t('log in')}</Link>
        </div>
      </div>

    </div>
  )
}
export { NewPassword }
export default withNamespaces()(NewPassword)

import { useNavigate } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { AuraButton } from '../../AuraButton'

const EmailValidationError = ({ t }) => {
  const navigate = useNavigate()
  return (
    <div>
      <div className='email-validation__icon-container error'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='38'
          height='38'
          viewBox='0 0 38 38'
          fill='none'
        >
          <path
            d='M19 0C24.0391 0 28.8718 2.00178 32.435 5.56497C35.9982 9.12816 38 13.9609 38 19C38 24.0391 35.9982 28.8718 32.435 32.435C28.8718 35.9982 24.0391 38 19 38C13.9609 38 9.12816 35.9982 5.56497 32.435C2.00178 28.8718 0 24.0391 0 19C0 13.9609 2.00178 9.12816 5.56497 5.56497C9.12816 2.00178 13.9609 0 19 0ZM19 8.14286C18.6554 8.14256 18.3145 8.21414 17.9991 8.35303C17.6838 8.49191 17.4008 8.69505 17.1684 8.94946C16.9359 9.20386 16.7591 9.50395 16.6492 9.83056C16.5393 10.1572 16.4987 10.5031 16.53 10.8463L17.5207 21.7197C17.5591 22.0853 17.7315 22.4237 18.0047 22.6696C18.2779 22.9156 18.6324 23.0517 19 23.0517C19.3676 23.0517 19.7221 22.9156 19.9953 22.6696C20.2685 22.4237 20.4409 22.0853 20.4793 21.7197L21.4673 10.8463C21.4986 10.5033 21.4581 10.1576 21.3483 9.8312C21.2386 9.50478 21.062 9.20482 20.8298 8.95046C20.5977 8.69609 20.3151 8.4929 20 8.35385C19.685 8.2148 19.3444 8.14294 19 8.14286ZM19 29.8571C19.5759 29.8571 20.1282 29.6284 20.5354 29.2211C20.9427 28.8139 21.1714 28.2616 21.1714 27.6857C21.1714 27.1098 20.9427 26.5575 20.5354 26.1503C20.1282 25.7431 19.5759 25.5143 19 25.5143C18.4241 25.5143 17.8718 25.7431 17.4646 26.1503C17.0573 26.5575 16.8286 27.1098 16.8286 27.6857C16.8286 28.2616 17.0573 28.8139 17.4646 29.2211C17.8718 29.6284 18.4241 29.8571 19 29.8571Z'
            fill='#D9534F'
          />
        </svg>
        <span>{t('No pudimos validar tu e-mail')}.</span>
      </div>
      <div className='p-16'>
        <p>
          {t('email_verification.message.1')}
          {/* Puede que el link de validación haya expirado. Por favor, intente iniciar sesión nuevamente: */}
        </p>
        <AuraButton
          onClick={() => {
            navigate('/login')
          }}
          className='email-validation__button--send-email'
          type='secondary'
        >
          {t('Ir a iniciar sesión')}
          {/* Ir a iniciar sesión */}
        </AuraButton>
        <p>
          {t('Si seguís teniendo problemas para validar tu cuenta')}:
          <a
            href='mailto:soporte@auravant.com?subject=Tengo%20problemas%20para%20validar%20mi%20correo'
            className='bold'
            style={{ display: 'block' }}
          >
            {t('Contactá a soporte')}
          </a>
        </p>
      </div>
    </div>
  )
}

const EmailValidationErrorWithNamespaces =
  withNamespaces()(EmailValidationError)

export { EmailValidationErrorWithNamespaces as EmailValidationError }

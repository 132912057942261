import { useContext, useEffect, useState } from 'react'
import { AuraButton } from '../../AuraButton'
import { MainContext } from '../../../../context/MainContext'
import './index.css'
import { AuraLoader } from '../../AuraLoader'
import { withNamespaces } from 'react-i18next'
import loginService from '../../../../services/Login'
import { useNavigate } from 'react-router-dom'

const SuspendedAccount = ({ t }) => {
  const navigate = useNavigate()

  const { setEmailValidationTitle, setEmailValidationClosable, email } =
    useContext(MainContext)

  const [timePending, setTimePending] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (email == null || email === '') {
      navigate('/login')
    }
    setEmailValidationTitle('Cuenta suspendida')
    setEmailValidationClosable(true)
    window.dataLayer.push({
      event: 'AuraviewAction',
      eventCategory: 'Pop-up',
      eventAction: 'view',
      eventLabel: 'Shown',
      eventValue: 1,
      eventNonInteraction: true,
      properties: {
        name: 'Email Verification',
        action: 'view',
        place: 'accounts'
      }
    })

    if (timePending != null && timePending !== false) return
    setPending(true)
    loginService
      .verifyEmail(email)
      .then(res => {
        return res.json()
      })
      .then(res => {
        if (res.info === 'ok') {
          disableButton(300)
        } else if (res.data?.available_in) {
          disableButton(res.data.available_in)
        }
      })
      .finally(() => {
        setPending(false)
      })
  }, [])

  function getTimeFromSeconds (seconds, options = {}) {
    const hours = Math.floor(seconds / 3600)
    const mins =
      options.hideHours === true
        ? Math.floor(seconds / 60)
        : Math.floor((seconds % 3600) / 60)
    const newSeconds = Math.floor((seconds % 3600) % 60)
    return options.hideHours
      ? `${mins}:${newSeconds}`
      : t('{{hours}} hs, {{mins}} min, {{seconds}} seg', {
          hours: Intl.NumberFormat('nu', { minimumIntegerDigits: 2 }).format(
            hours
          ),
          mins: Intl.NumberFormat('nu', { minimumIntegerDigits: 2 }).format(
            mins
          ),
          seconds: Intl.NumberFormat('nu', { minimumIntegerDigits: 2 }).format(
            newSeconds
          )
        })
  }

  function enableButton () {
    setTimePending(false)
  }

  function disableButton (time) {
    let timeToSubstract = 0
    setTimePending(
      t('disponible_en', {
        tiempo: getTimeFromSeconds(time, { hideHours: true })
      })
    )
    const interval = setInterval(() => {
      timeToSubstract = timeToSubstract + 1
      if (timeToSubstract >= time) {
        clearInterval(interval)
        enableButton()
      } else {
        const currTime = time - timeToSubstract
        setTimePending(
          t('disponible_en', {
            tiempo: getTimeFromSeconds(currTime, { hideHours: true })
          })
        )
      }
    }, 1000)
  }

  return (
    <div className='p-16'>
      <p>
        {t(
          'Por tu seguridad, necesitamos que valides tu e-mail ingresando al link que enviamos a'
        )}
        :<span className='email-validation__email'>{email}</span>
      </p>
      {/.*@gmail\.com/.test(email) && (
        <AuraButton
          className='email-validation__button--send-email'
          type='secondary'
          onClick={() => {
            window.dataLayer.push({
              event: 'AuraviewAction',
              eventCategory: 'Pop-up',
              eventAction: 'view',
              eventLabel: 'Clicked',
              eventValue: 1,
              eventNonInteraction: true,
              properties: {
                name: 'Email Verification',
                source: 'ir a gmail',
                action: 'view',
                place: 'accounts'
              }
            })
            window.open('https://gmail.com/', '_blank')
          }}
        >
          {t('Ir a Gmail')}
        </AuraButton>
      )}
      <p>
        {t('Si no lo encontrás, revisá la carpeta de spam o correo no deseado')}
        .
      </p>
      <p>
        {t(
          'Si tu e-mail es incorrecto o estás teniendo problemas para validar tu cuenta'
        )}
        :
        <a
          style={{ display: 'block' }}
          onClick={() => {
            window.dataLayer.push({
              event: 'AuraviewAction',
              eventCategory: 'Pop-up',
              eventAction: 'view',
              eventLabel: 'Clicked',
              eventValue: 1,
              eventNonInteraction: true,
              properties: {
                name: 'Email Verification',
                source: 'contacta soporte',
                action: 'view',
                place: 'accounts'
              }
            })
          }}
          href='mailto:soporte@auravant.com?subject=Tengo%20problemas%20para%20validar%20mi%20correo'
        >
          {t('Contactá a soporte')}
        </a>
      </p>
      {!pending ? (
        <a
          disabled={timePending != null && timePending !== false}
          className={`email-validation__button--send-email w-full ${
            timePending != null && timePending !== false ? 'disabled' : ''
          }`}
          type='text'
          onClick={() => {
            if (timePending != null && timePending !== false) return
            window.dataLayer.push({
              event: 'AuraviewAction',
              eventCategory: 'Pop-up',
              eventAction: 'view',
              eventLabel: 'Clicked',
              eventValue: 1,
              eventNonInteraction: true,
              properties: {
                name: 'Email Verification',
                source: 'reenviar email',
                action: 'view',
                place: 'accounts'
              }
            })
            setPending(true)
            loginService
              .verifyEmail(email)
              .then(res => {
                return res.json()
              })
              .then(res => {
                if (res.info === 'ok') {
                  disableButton(300)
                } else if (res.data?.available_in) {
                  disableButton(res.data.available_in)
                }
              })
              .finally(() => {
                setPending(false)
              })
          }}
        >
          {t('Reenviar e-mail')} {timePending}
        </a>
      ) : (
        <AuraLoader />
      )}
    </div>
  )
}

const WithNamespaces = withNamespaces()(SuspendedAccount)

export { WithNamespaces as SuspendedAccount }

import { useContext, useEffect } from 'react'
import { MainContext } from '../../../../context/MainContext'
import { withNamespaces } from 'react-i18next'

const EmailSent = ({ email, t }) => {
  const { setEmailValidationClosable, setEmailValidationTitle } =
    useContext(MainContext)

  useEffect(() => {
    setEmailValidationTitle(' e-mail de validación reenviado')
    setEmailValidationClosable(true)
  }, [])

  return (
    <div className='p-16'>
      <p>
        {t('Reenviamos el e-mail de validación a')}:
        <span className='email-validation__email'>{email}</span>
      </p>
      <p>
        {t('Recordá que tenés 48 horas desde que lo recibiste para validarlo')}.
      </p>
      <p>
        {t(
          'Asegurate de que no esté en la carpeta de spam o correo no deseado'
        )}
        .
      </p>
      <p>
        {t(
          'Si tu e-mail es incorrecto o estás teniendo problemas para validar tu cuenta'
        )}
        :<a style={{ display: 'block' }}>{t('Contactá a soporte')}</a>
      </p>
    </div>
  )
}

const EmailSentWithNamesspaces = withNamespaces()(EmailSent)

export { EmailSentWithNamesspaces as EmailSent }
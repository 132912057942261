import { React,useEffect } from 'react'

import { Select } from 'antd';

import i18n from '../../i18n/i18n';
import { withNamespaces } from 'react-i18next';

const { Option } = Select;

const PowerByAuravant = () => {
    return ( 
        <div className="powerby ">
            <div className="tag">
                Powered by
            </div>
            <div className="auravant">
                <img className="logo auravant" src="/assets/logo.svg" alt="logo" />
            </div>
        </div>
    )
}

const Footer = props => {

    const changeLocale = locale => {
        props.setLocale( locale )
        i18n.changeLanguage( locale );
    }
    
  

    return (
        <div className="footer premium-powered-by">
            <div className="language">
                <Select defaultValue={ i18n.language }  onChange={ changeLocale } size="small" className="premium-button-minor" >
                    <Option value="es_AR"> { props.t( 'Spanish (Latin America)' ) } </Option>
                    <Option value="pt_BR"> { props.t( 'Portugues' ) } </Option>
                    <Option value="es_ES"> { props.t( 'Spanish (ESP)' ) } </Option>
                    <Option value="en_US"> { props.t( 'English' ) } </Option>
                    <Option value="fr_FR"> { props.t( 'French' ) } </Option>
                </Select>
            </div>
            { props.powerbyauravant ? <PowerByAuravant /> : null }
        </div>
    )
}

export default withNamespaces()(Footer)
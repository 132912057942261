import { Children, cloneElement } from 'react'
import './index.css'
import { AuraLoader } from '../AuraLoader'

const styles = {
"button": "button",
"disabled": "disabled",
"button--primary": "button--primary",
"danger": "danger",
"button--secondary": "button--secondary",
"button--text": "button--text",
"shadowed": "shadowed",
}

// interface IButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
//   shadowed?: boolean
//   disabled?: boolean
//   type?: 'primary' | 'secondary' | 'text'
//   htmlType?: 'submit' | 'reset' | 'button'
//   loading?: boolean
//   danger?: boolean
// }

const AuraButton = ({ type: auraType, htmlType: type, children, className, shadowed, disabled, loading, danger, ...rest }) => {
  const typeClassName = (typeof auraType === 'undefined' || auraType === 'primary')
    ? 'button--primary'
    : auraType === 'secondary'
      ? 'button--secondary'
      : 'button--text'

  const extendedClassName = className != null ? className : ''

  return (
    <button
      disabled={disabled}
      className={`${
        styles.button
      } ${
        styles[typeClassName]
      } ${
        (shadowed) && auraType !== 'text' ? styles.shadowed : ''
      } ${
        ((disabled) || (loading)) ? styles.disabled : ''
      } ${
        (danger) ? styles.danger : ''
      } ${
        extendedClassName
      }`}
      type={type}
      {...rest}
    >{
      (loading)
        ? <AuraLoader radius='28px' />
        : Children.map(children, (child) => {
          const displayName = child?.type?.displayName
          if (displayName === 'AuraIcon') {
            return cloneElement(child, { size: 'x-small' })
          }
          return child
        })
}
    </button>
  )
}

export { AuraButton }

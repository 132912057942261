import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './login.css'
import { Button } from 'antd';
import Title from '../../components/Title'
import { useCookies } from 'react-cookie'
import { regExpEmail } from '../../../constants/constants';
import toastr from 'toastr'
import loginService from '../../../services/Login';
import tenancyService from '../../../services/tenancy';
import dompurify from 'dompurify'
import { withNamespaces } from 'react-i18next';
import { buildQueryString, getQueryParams } from '../../../constants/fx';
import { MainContext } from '../../../context/MainContext';

const handleError = (code, t) => {

  switch (code) {
    case 1:
      toastr.error(t('Incorrect username and/or password'))
      break;
    case 2:
      toastr.error(t('Incorrect username and/or password'))
      break;
    case 3:
      toastr.error(t('Please complete with your email and password to login'))
      break;
    case 4:
      toastr.error(t('The user do not exist in this space'))
      break;
    case 5:
      // toastr.error( t( 'This user is suspended. Please contact with the admin of your space to activate it' ) )
      postMessage({ type: "suspended_user" })
      break;
    case 'INVALID_OTP':
      toastr.error(t('The entered code is incorrect'))
      break;
    case 'SESSION_EXPIRED':
      toastr.error(t('Your session has expired. Please login again'))
      break;
    case 'EMAIL_VERIF_PENDING':
      postMessage({ type: "EMAIL_VERIF_PENDING" })
      break;
    default:
      toastr.error(t('Oops! An error occurred. Please try again later'))
      break;
  }
}

const Login = props => {

  const { email, setEmail } = useContext(MainContext)

  const [validatingSession, setValidationSession] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();
  const [errors, setErrors] = useState({ email: '', otp: '' });
  const [askSecondFactor, setAskSecondFactor] = React.useState({ show: false });
  const [secondFactor, setSecondFactor] = React.useState('');
  const [loadingFactor, setLoadingFactor] = useState(false);
  const location = useLocation()

  let query = getQueryParams(location.search);

  useEffect(() => {

    setValidationSession(true)
    if (cookies['com.auravant.auth'] && cookies['com.auravant.auth'] !== '-') {

      if (props.checkout) {

        loginService.ping('my')
          .then(() => {

            loginService
              .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
              .then(res => {

                if (res.data.redirect_url) window.location.href = res.data.redirect_url

              })
              .catch(err => {
                tenancyService.redirectToAuraview()
              })

          })
          .catch(err => {
            loginService
              .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
              .then(res => {

                if (res.data.redirect_url) window.location.href = res.data.redirect_url

              })
              .catch(err => {
                tenancyService.redirectToAuraview()
              })
          })

      } else {

        tenancyService.redirectToAuraview(props?.workspace?.subdomain, props.deeplink, props.startFeature)

      }

    } else {

      setValidationSession(false)

    }

  }, [cookies, props.workspace, props.deeplink, props.startFeature, props.checkout])

  const validateEmail = (email) => {
    if (email) {
      if (!regExpEmail.test(email)) {
        setErrors({ ...errors, email: props.t('Invalid format') })
      } else {
        setErrors({ ...errors, email: '' })
      }
    } else {
      setErrors({ ...errors, email: '' })
    }
  }

  const handleChange = (ev, inputName) => {
    if (ev.target.value.includes(" ")) {
      ev.target.value = ev.target.value.replace(/\s/g, "");
    }
    if (inputName === "email") {
      if (regExpEmail.test(ev.target.value)) {
        setErrors({ ...errors, email: "" })
      }
      setEmail(ev.target.value)
    }
  }

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const login = () => {

    window.dataLayer.push({ 'event': 'AuraviewAction', 'eventCategory': 'Log in', 'eventAction': 'click', 'eventLabel': 'Started', 'eventValue': 1, 'eventNonInteraction': true, 'properties': { 'Language': props.locale, place: 'accounts' } });

    setLoading(true)

    const data = {
      username: dompurify.sanitize(document.getElementById('username').value, { USE_PROFILES: { html: false } }),
      password: dompurify.sanitize(document.getElementById('password').value, { USE_PROFILES: { html: false } }),
      space: props.workspace && props.workspace.subdomain ? props.workspace.subdomain : undefined
    }

    if (!data.username || !data.password) {
      handleError(3, props.t)
      setLoading(false);
      return
    }

    loginService.login({ ...data })
      .then(res => {
        if (props.checkout) {

          loginService.ping('my')
            .then(() => {

              loginService
                .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
                .then(res => {

                  if (res.data.redirect_url) window.location.href = res.data.redirect_url

                })
                .catch(err => {
                  tenancyService.redirectToAuraview()
                })

            })
            .catch(err => {

              loginService
                .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
                .then(res => {

                  if (res.data.redirect_url) window.location.href = res.data.redirect_url

                })
                .catch(err => {
                  tenancyService.redirectToAuraview()
                })

            })

        } else {

          tenancyService.redirectToAuraview(props?.workspace?.subdomain, props.deeplink, props.startFeature)

        }
      })
      .catch(err => {
        setLoading(false)
        const code = err?.response?.data?.code;

        if (err?.response?.data?.hasOwnProperty('redis') && err.response.data.redis && code == 7) {
          return setAskSecondFactor({ show: true, redis: err.response.data.redis })
        }
        handleError(code, props.t)


      })

  }

  //LOGIN WITH GOOGLE
  const loginGoogle = () => {

    try {

      window.dataLayer.push({ 'event': 'AuraviewAction', 'eventCategory': 'Log in SSO', 'eventAction': 'click', 'eventLabel': 'Clicked', 'eventValue': 1, 'eventNonInteraction': true, 'properties': { 'Language': props.locale, 'Source': 'Google SSO', place: 'accounts' } });

    } catch (error) {
      console.log('Error registrando eventos.')
    }


    const URL_API = tenancyService.getAPICurrentBaseURL()
    const queryString = buildQueryString()

    if (props.workspace && props.workspace.subdomain) {
      window.location.href = `${URL_API}/auth/integrations/google?context=signin${props.checkout ? (`&redirect_url=${window.location.origin}/checkout?payment=${query.get('payment')}`) : ''}&locale=${props.locale}&subdomain=${(props.workspace && props.workspace.subdomain ? props.workspace.subdomain : '')}&${queryString}`;
    } else {
      window.location.href = `${URL_API}/auth/integrations/google?context=signin${props.checkout ? (`&redirect_url=${window.location.origin}/checkout?payment=${query.get('payment')}`) : ''}&locale=${props.locale}&${queryString}`;
    }
  }

  const verifySecondFactor = () => {
    setErrors({ ...errors, otp: false });
    setLoadingFactor(true)
    loginService.verifyCode({ otp: dompurify.sanitize(secondFactor.trim(), { USE_PROFILES: { html: false } }), redis: askSecondFactor.redis })
      .then((res) => {

        if (props.checkout) {

          loginService.ping('my')
            .then(() => {

              loginService
                .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
                .then(res => {

                  if (res.data.redirect_url) window.location.href = res.data.redirect_url

                })
                .catch(err => {
                  tenancyService.redirectToAuraview()
                })

            })
            .catch(err => {

              loginService
                .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
                .then(res => {

                  if (res.data.redirect_url) window.location.href = res.data.redirect_url

                })
                .catch(err => {
                  tenancyService.redirectToAuraview()
                })

            })

        } else {

          tenancyService.redirectToAuraview(props?.workspace?.subdomain, props.deeplink, props.startFeature)

        }


      })
      .catch(e => {
        setLoadingFactor(false)
        handleError(e.response.data.code, props.t);
        if (e.response.data.code == 'INVALID_OTP') {
          setErrors({ ...errors, otp: true })
        }
        if (e.response.data.code == 'SESSION_EXPIRED') {
          setAskSecondFactor({ show: false, redis: '' });
          setLoading(false)
        }
      })
  }

  const keyUpHandler = event => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !errors.email) {
      login()
    }
  }

  if (validatingSession) {
    return (
      <div className="loginContainer">
        <div className="loading">
          <div className="icon">
            <img src="/assets/loading.svg" alt="Loading"></img>
          </div>
          <div className="tag"> ..Cargando.. </div>
        </div>
      </div>
    )
  }

  return (
    askSecondFactor.show ?
      <div className='secondFactor'>
        <div className='title'>{props.t('Two Factor Authentication')}</div>
        <div className='content'>
          {errors.otp && <div className='error-code'>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="#FC2F02" /><path fillRule="evenodd" clipRule="evenodd" d="M5.00015 3.93827C5.00083 2.2929 5.0009 2.12146 5.08349 2.05883C5.09309 2.05154 5.10381 2.04573 5.11578 2.03924L5.12577 2.03379C5.17138 2.0085 5.36855 2.00077 5.98489 2.00008C6.85303 1.99914 6.89155 2.00451 6.95088 2.13473C6.973 2.18327 6.98048 2.68037 6.98048 4.10431C6.98048 5.52825 6.973 6.02535 6.95088 6.07389C6.89167 6.20386 6.85237 6.20941 5.98985 6.20941C5.12733 6.20941 5.08802 6.20386 5.02881 6.07389C5.00664 6.02527 4.99944 5.5282 5.00008 4.09695L5.00015 3.93827ZM5.00008 8.18572C5.00102 7.33093 5.00491 7.30158 5.12577 7.23461C5.21534 7.18496 6.75689 7.1856 6.85306 7.23533C6.97493 7.29836 6.98048 7.33992 6.98048 8.19067C6.98048 9.04143 6.97493 9.08299 6.85306 9.14602C6.76205 9.19307 5.23662 9.19815 5.13473 9.15171C5.00451 9.09237 4.99914 9.05386 5.00008 8.18572ZM6.48648 5.7153V4.10552V2.49574H5.99117H5.49585V4.10552V5.7153H5.99117H6.48648ZM6.48563 8.19057V8.68588H5.99031H5.495V8.19057V7.69525H5.99031H6.48563V8.19057Z" fill="white" /></svg>
            {props.t('invalid code')}
          </div>
          }
          <div>
            <input type='text' placeholder={props.t('Enter code...')} key='code' onChange={(ev) => setSecondFactor(ev.target.value)} className={errors.otp ? 'error-otp' : ''} onKeyUp={(ev) => ev.code == 'Enter' || ev.code == 'NumpadEnter' && verifySecondFactor()} />
            <p>{props.t('Enter the two-factor app code on your mobile device.')}</p>
          </div>
          <Button className="login premium-button-action" type="primary" size="large" onClick={verifySecondFactor} loading={loadingFactor} disabled={loadingFactor}>
            <span className='action-go'>{!loadingFactor ? props.t('check code') : ''}</span>
          </Button>
        </div>
      </div>
      :
      <div className="loginContainer">
        <Title workspace={props.workspace} />

        {

          props.workspace && props.workspace.data && props.workspace.data.premium ?

            null

            :

            <React.Fragment>
              <div className='welcome'>{props.t('Welcome!')}</div>

              <div className="google-singin-button" onClick={loginGoogle}>
                <span className='svg'><svg viewBox="0 0 48 48" width="22px" height="22px"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg></span>
                <span>{props.t('Continue with Google')}</span>
              </div>
              <div className='separator'>
                <svg width="147" height="2" viewBox="0 0 147 1" fill="none">
                  <rect width="147" height="1" rx="0.5" fill="#828282" />
                </svg>
                o
                <svg width="147" height="2" viewBox="0 0 147 1" fill="none">
                  <rect width="147" height="1" rx="0.5" fill="#828282" />
                </svg>
              </div>
            </React.Fragment>

        }
        {
          props.workspace.data && props.workspace.data?.messages?.welcome && props.workspace.data?.messages?.welcome[props.locale]
            ?
            <span className='welcome'> {props.workspace.data?.messages?.welcome[props.locale]}</span>
            :
            null
        }
        {
          props.workspace.data && props.workspace.data?.messages?.tooltip && props.workspace.data?.messages?.tooltip[props.locale]
            ?
            <span className='tooltip' dangerouslySetInnerHTML={{ __html: props.workspace.data?.messages?.tooltip[props.locale] }} />
            :
            null
        }
        <div className="form">
          <span className='weight-text'>{props.t('Email')}</span>
          <div className="field-wrapper input user">
            <input id="username" name="username" type="text" className={`form-control ${errors.email ? 'error premium-input' : 'premium-input'}`} placeholder={props.t('Enter email...')} onBlur={(ev) => validateEmail(ev.target.value)} onChange={(evt) => handleChange(evt, "email")} value={email} key='user' style={{ fontSize: '16px' }} />
            <svg className="login-icon" viewBox="0 0 30 30"><path d="M26.32,5.35H3.68A1.26,1.26,0,0,0,2.42,6.61V23.39a1.26,1.26,0,0,0,1.26,1.26H26.32a1.26,1.26,0,0,0,1.26-1.26V6.61A1.26,1.26,0,0,0,26.32,5.35Zm.42,18a.42.42,0,0,1-.42.42H3.68a.42.42,0,0,1-.42-.42V6.61a.42.42,0,0,1,.42-.42H26.32a.42.42,0,0,1,.42.42Z" style={{ fill: '#a3a3bf' }} /><path d="M25.81,7.18A.48.48,0,0,0,25.52,7a.45.45,0,0,0-.31.1l-9.67,8.13a.85.85,0,0,1-1.08,0L4.79,7.13a.43.43,0,0,0-.42-.07.42.42,0,0,0-.27.32.41.41,0,0,0,.15.39l9.67,8.13a1.69,1.69,0,0,0,2.16,0l9.67-8.13a.39.39,0,0,0,.15-.28A.44.44,0,0,0,25.81,7.18Z" style={{ fill: '#a3a3bf' }} /><path d="M10.08,16,4.21,22.26a.42.42,0,0,0,.21.7.38.38,0,0,0,.4-.13l5.87-6.29a.41.41,0,0,0,.11-.41.45.45,0,0,0-.32-.29A.43.43,0,0,0,10.08,16Z" style={{ fill: '#a3a3bf' }} /><path d="M19.92,16a.43.43,0,0,0-.4-.13.45.45,0,0,0-.32.29.41.41,0,0,0,.11.41l5.87,6.29a.41.41,0,0,0,.58,0,.42.42,0,0,0,0-.59Z" style={{ fill: '#a3a3bf' }} /></svg>
            {errors.email && <b className="error-msg">{errors.email}</b>}
          </div>

          <span className='weight-text'>{props.t('Password')}</span>
          <div className="field-wrapper input password">
            <input id="password" name="password" type={passwordVisible ? 'text' : 'password'} className="form-control premium-input" placeholder={props.t('Enter password...')} onKeyUp={keyUpHandler} onChange={(evt) => handleChange(evt, "password")} key='password' style={{ fontSize: '16px' }} />
            <span toggle="#password" className={!passwordVisible ? "toggle-password" : "toggle-password-closed"} title="ver contraseña" onClick={handlePasswordVisibility}></span>
            <svg className="login-icon" viewBox="0 0 30 30"><path d="M19.54,27.75H10.46A4.85,4.85,0,0,1,5.61,22.9V15.66a2.12,2.12,0,0,1,2.11-2.12H22.28a2.12,2.12,0,0,1,2.11,2.12V22.9A4.85,4.85,0,0,1,19.54,27.75ZM7.72,14.54a1.12,1.12,0,0,0-1.11,1.12V22.9a3.86,3.86,0,0,0,3.85,3.85h9.08a3.86,3.86,0,0,0,3.85-3.85V15.66a1.12,1.12,0,0,0-1.11-1.12Z" style={{ fill: '#a3a3bf' }} /><path d="M22.18,10.38a.5.5,0,0,1-.5-.5,6.64,6.64,0,0,0-13.27,0,.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5,7.64,7.64,0,0,1,15.27,0A.5.5,0,0,1,22.18,10.38Z" style={{ fill: '#a3a3bf' }} /><path d="M22.18,14.54a.5.5,0,0,1-.5-.5V9.88a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5V14A.5.5,0,0,1,22.18,14.54Z" style={{ fill: '#a3a3bf' }} /><path d="M15,23.29a2.54,2.54,0,1,1,2.54-2.54A2.54,2.54,0,0,1,15,23.29Zm0-4.08a1.54,1.54,0,1,0,1.54,1.54A1.54,1.54,0,0,0,15,19.21Z" style={{ fill: '#a3a3bf' }} /></svg>
          </div>

          <div className="recovery">
            <Link to={"/recovery" + location.search}> {props.t('Did you forget your password?')} </Link>
          </div>
        </div>
        <div className="actions">

          {
            !props.workspace ||
              !props.workspace.data ||
              props.workspace.data.signup
              ?
              <Link to={props.checkout ? `/checkout-register${location.search}` : `/register${location.search}`}>
                <Button className="premium-button-minor" type="text" size="large" disabled={loading} > <span className='action-font'>{props.t('Sign Up')} </span></Button>
              </Link>
              :
              null
          }

          <Button className="login premium-button-action" block type="primary" size="large" onClick={login} loading={loading} disabled={errors.email}>
            <span className='action-go'>{!loading ? props.t('Go') : ''}</span>
          </Button>
        </div>
      </div>
  )
}
export { Login }
export default withNamespaces()(Login)
const getQueryParams = ( search ) => {
    return new URLSearchParams( search );
  }

  // FX PARA SACAR EL PAIS SEGUN EL LENGUAGE SELECCIONADO ( GDM )
const parseCountryFromLanguage = ( language ) => {

    switch ( language ) {
      case 'es_AR':
        return 'Argentina'
      case 'pt_BR':
        return 'Brasil'
      case 'en_US':
        return 'United States'
      default:
        return 'Argentina'
    }
  
  }


  function buildQueryString() {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams();
  
    const queryParameters = [...url.searchParams.entries()];
    queryParameters.forEach(([key, value]) => {
      if (key.startsWith('utm')) {
        queryParams.append(key, value);
      }
    });
  
    return queryParams.toString();
  }

export {
    getQueryParams,
    parseCountryFromLanguage,
    buildQueryString
}
import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationAR from './es_AR.json';
import translationUS from './en_US.json';
import translationBR from './pt_BR.json';
import translationES from './es_ES.json';
import translationFR from './fr_FR.json';

// the translations
export const resources = {
  'es_AR': {
    translation: translationAR
  },
  'en_US': {
      translation: translationUS
  },
  'es_ES': {
    translation: translationES
  },
  'pt_BR': {
    translation: translationBR
  },
  'fr_FR': {
    translation: translationFR
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es_AR",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
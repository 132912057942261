import './index.css'

const styles = {
  container: "container",
  spinner: "spinner",
}

// interface AuraLoaderProps {
//   radius?: string
//   color?: string
//   strokeWidth?: string
// }

const AuraLoader = (props) => {
  const radius = props.radius != null && props.radius.trim() !== '' ? props.radius : '32px'
  const color = props.color != null && props.color !== '' ? props.color : '#555'
  const strokeWidth = props.strokeWidth != null && props.strokeWidth !== '' ? props.strokeWidth : '2px'

  return (
    <div
      className={styles.container} style={{
        height: radius,
        width: radius
      }}
    >
      <span
        className={styles.spinner} style={{
          borderWidth: strokeWidth,
          borderStyle: 'solid',
          borderColor: `${color} ${color} transparent`
        }}
      />
    </div>
  )
}

export { AuraLoader }

import { Outlet, useNavigate } from 'react-router-dom'
import quitIcon from '../../../assets/icons/actionQuit.svg'
import './index.css'
import { useContext } from 'react'
import { MainContext } from '../../../context/MainContext'

export const EmailValidation = () => {
  const navigate = useNavigate()
  const { emailValidationClosable, emailValidationTitle } =
    useContext(MainContext)

  return (
    <div className='email-validation__container'>
      <div className='email-validation__header'>
        <span className='email-validation__title'>{emailValidationTitle}</span>
        {emailValidationClosable && (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/login')
            }}
          >
            <img src={quitIcon} alt='' />
          </div>
        )}
      </div>
      <div className='email-validation__content'>
        <Outlet />
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

const EmailValidationSuccessful = ( { t } ) => {
  const navigate = useNavigate()
  const [secs, setSecs] = useState(5)

  useEffect(() => {
    const interval = setInterval(() => {
      setSecs(prev => {
        if (prev <= 0) {
          navigate('/login', { replace: true })
        }
        return prev - 1
      })
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div>
      <div className='email-validation__icon-container success'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='38'
          height='38'
          viewBox='0 0 38 38'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM29.9751 13.9594C29.9751 13.5481 29.8118 13.1537 29.521 12.8628C29.377 12.7187 29.2059 12.6044 29.0177 12.5263C28.8295 12.4483 28.6277 12.4082 28.4239 12.4082C28.2202 12.4082 28.0184 12.4483 27.8301 12.5263C27.6419 12.6044 27.4709 12.7187 27.3268 12.8628L16.7251 23.4656L11.9728 18.7133C11.8297 18.5651 11.6585 18.447 11.4693 18.3657C11.2801 18.2844 11.0766 18.2416 10.8706 18.2398C10.6647 18.238 10.4604 18.2773 10.2698 18.3553C10.0792 18.4333 9.90602 18.5484 9.76039 18.694C9.61476 18.8397 9.4996 19.0129 9.42161 19.2035C9.34362 19.3941 9.30438 19.5983 9.30617 19.8043C9.30796 20.0102 9.35075 20.2137 9.43203 20.403C9.51332 20.5922 9.63148 20.7633 9.77962 20.9064L15.5556 26.6814C15.7093 26.8351 15.8917 26.9569 16.0924 27.0401C16.2931 27.1233 16.5083 27.1661 16.7256 27.1661C16.9429 27.1661 17.1581 27.1233 17.3588 27.0401C17.5596 26.9569 17.742 26.8351 17.8956 26.6814L29.521 15.056C29.8118 14.7651 29.9751 14.3707 29.9751 13.9594Z'
            fill='#058913'
          />
        </svg>
        <span>{t('Tu e-mail se validó con éxito')}.</span>
      </div>
      <div className='p-16'>
        <p>
          {
          t(
            'email_verification.successful.redirection',
            { secs }
          )
          // t(
          //   'Te redirigiremos en {{secs}} para que inicies sesión, o accede a través de',
          //   { secs }
          // )
          }{' '}
          <Link className='bold' to={'/login'}>
            {t('este link')}
          </Link>
        </p>
      </div>
    </div>
  )
}

const EmailValidationSuccessfulWithNamespaces = withNamespaces()(EmailValidationSuccessful)

export { EmailValidationSuccessfulWithNamespaces as EmailValidationSuccessful }
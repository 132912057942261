import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { withNamespaces, Trans } from 'react-i18next';
import Title from '../../components/Title'
import { regExpEmail } from '../../../constants/constants';
import toastr from 'toastr'
import { Link } from 'react-router-dom';
import recoveryService from '../../../services/Recovery'
import amplitude from 'amplitude-js'

import './recovery.css'

const handleError = ( code, t ) => {


  switch ( code ) {
    case 10:
      toastr.error( t( "User with pending invitation" ) )
      break;
    case 99:
      toastr.error( t( "Please complete all fields" ) )
      break;
  
    default:
      toastr.error( t( "There was a problem sending the email, if you don't remember or had a problem recovering your password, write to us at soporte@auravant.com" ) )
      break;
  }

}

const Form = props => {
  const [errors, setErrors] = useState({ email: '' });

  const validateEmail = (email) => {
    if(email){
      if (!regExpEmail.test(email)){
        setErrors({...errors, email: props.t('Invalid format')})
    } else {
        setErrors({...errors, email: ''})
      }
  } else{
      setErrors({...errors, email:''})
  }
}

const handleChange = (ev) => {
  if (ev.target.value.includes(" ")) {
      ev.target.value = ev.target.value.replace(/\s/g, "");
  }
  if (regExpEmail.test(ev.target.value)){
    setErrors({ ...errors, email:"" });
    props.setEmail(ev.target.value);
  }
}

  return (
    <div>
        <div className="form">
          <div className="field-wrapper input">
              <input id="recovery-email" name="email" type="text" className={`form-control ${errors.email  ? 'error' : 'premium-input'}`} placeholder={ props.t( 'Email' ) } onBlur={(ev) => validateEmail(ev.target.value) } onChange={handleChange} />
              {errors.email && <b className="error-msg">{errors.email}</b>}
          </div>

        </div>
        <div className="actions">

          <Button type="primary" block size="large" onClick={ props.recover } disabled={errors.email} loading={props.loading} className="login premium-button-action">
             <span className='action-go'>{ props.t( 'Recover Password' ) }</span> 
          </Button>

          <div className="login" >
            {props.t('Don\'t need this? Please')} <Link  to={"/login"}>{props.t('log in')}</Link> 
          </div>

        </div>
    </div>
  )
}

const Ready = props => {
  // const email = `xxxx@${(props.email.split('@')[1])}`;
  return (
    <div className="ready">

      <div className="title">
        { props.t( 'Email Sent!' ) }
      </div>
      <div className="message">
        <Trans i18nKey="Check your inbox!" email={props.email}>
        Check your inbox! Access the link that we send you to {{ email: props.email }} to recover your account. If you can't find it, don't forget to check your spam or junk mail box.
        </Trans>
      </div>

    </div>
  )
}

const Recovery = props => {

  const [ ready, setReady ] = useState( false )
  const [ loading, setLoading ] = useState(false)
  const [ email, setEmail ] = useState('');

  useEffect( () => {
    amplitude.getInstance().logEvent('Password Recovery Started');
  }, [])

  const recover = () => {

    const jsonData = {
      email: document.getElementById( 'recovery-email' ).value,
      space: props.workspace && props.workspace.subdomain
    }

    if ( !jsonData.email ){
      handleError( 99, props.t )
      return
    }
    setLoading(true);

    window.grecaptcha.enterprise
      .ready(function() {
        window.grecaptcha.enterprise
          .execute(
            '6Lc9nQIkAAAAAF2jJBlcmJWiOxLzSlqyU0XQ0VLY', 
            { action: 'login' })
            .then( token => {

                jsonData[ 'captcha' ] = token

                recoveryService.recover( jsonData )
                                                  .then( res => {
                                                    setLoading(false)
                                                    setReady( true )
            
                                                  })
                                                  .catch( err => {
                                                    setLoading(false)
            
                                                    const code = err.response.data.code
            
                                                    handleError( code, props.t )
                                                  })
            });
    });

  }

  return (
    <div className="recoveryContainer">
      
      <Title workspace={ props.workspace } />

      {
        ready ? <Ready t={ props.t } email={email}/> : <Form recover={ recover } t={ props.t } loading={loading} setEmail={setEmail} />
      }
    </div>
  )
}
export { Recovery }
export default withNamespaces()(Recovery)

const env = {

    production: true,
    amplitude_key: '918ff8483f73d5c6b2407ad4f370a67d',
    base_accounts_url_prefix: '',
    base_platform_url_subdomain: 'auraview',
    base_platform_url_prefix: '',
    accounts_prefix_key: 'accounts',
    api_prefix: '',
    api_url_key: 'api'
  }
  
  export default env


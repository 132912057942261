import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import termsAndConditionsService from "../../services/TermsAndConditions"
import { withNamespaces } from 'react-i18next';
import i18n from "../../i18n/i18n";
import './TermsAndConditions.css'
import tenancyService from "../../services/tenancy";

const languageTagsElement = 'h1'
const sectionTagsElement = 'h2'

const TermsAndConditions = ({ language, workspace, t, setLocale }) => {

    const [ fetchingStatus, setFetchingStatus ] = useState( { loading: true, data: null, error: null } )
    const [ sectionIndexSelected, setSectionIndexSelected ] = useState( 0 )
    
    const contentRef = useRef( null )
    const iframeRef = useRef( null )

    // EFECTO PARA PEGARLE A UNA API Y TRAER TERMINOS Y CONDICIONES AL CONSTRUIR EL MODAL
    useLayoutEffect( () => {

        setFetchingStatus( { loading: true, data: null, error: null } )
        termsAndConditionsService.get( workspace?.workspace ) 
            .then( res => {

                setFetchingStatus( { loading: false, data: res.data.data, error: null } )

                if ( res.data.data.legacy ){

                    iframeRef.current.src = tenancyService.getAPICurrentBaseURL()+"/terms?desktop=" + workspace?.workspace

                } else {

                    const workspaceLanguageContent = filterHTMLFromLanguage( res.data.data.workspace )[ language ]
                    const generalLanguageContent = filterHTMLFromLanguage( res.data.data.general )[ language ]
    
                    contentRef.current.innerHTML = ( workspaceLanguageContent || '' ) + ( generalLanguageContent || '' )
                }

            })
            .catch( err => {
                setFetchingStatus( { loading: false, data: null, error: true } )
            })

    }, [ ] )

    // EFECTO PARA CAMBIAR EL TEXTO AL VARIAR EL LENGUAJE
    useEffect( () => {

        if ( fetchingStatus && fetchingStatus.data ){

            const workspaceLanguageContent = filterHTMLFromLanguage( fetchingStatus.data.workspace )[ language ]
            const generalLanguageContent = filterHTMLFromLanguage( fetchingStatus.data.general )[ language ]

            contentRef.current.innerHTML = ( workspaceLanguageContent || '' ) + ( generalLanguageContent || '' )
            
        }

    }, [ language ] )

    // CALLBACK PARA CUANDO SE SCROLLEA, MARCAR SOBRE EL NAVBAR LA SECCION ACTUAL
    const scrollFx = ev => {
        
        Array( contentRef.current.querySelectorAll( sectionTagsElement ).length )
            .find( ( item, index ) => {

                const element = contentRef.current.querySelectorAll( sectionTagsElement )[ index ]
                
                if ( element.offsetTop >= ev.target.scrollTop ){
                    setSectionIndexSelected( index )

                    return true
                }

                return false

            } )

    }

    // FX PARA SCROLLEAR A LA SECCION DEL INDICE PARAMETRO
    const scrollToItem = index => {

        contentRef.current.querySelectorAll( sectionTagsElement )[ index ].scrollIntoView({ behavior: 'smooth'})

    }

    const changeLocale = locale => {

        setLocale( locale )
        i18n.changeLanguage( locale );

    }

    if ( fetchingStatus.loading ){

        return <div className="terms-and-conditions"><div className="loading"><img src="/assets/loading.svg" alt="Loading"></img></div></div>

    } else if ( fetchingStatus.data.legacy ){

        return (
            <div className="terms-and-conditions iframe-readme">
                <iframe className="terms-iframe" ref={ iframeRef }></iframe>
            </div>
        )
    } else {

        const workspaceLanguageContent = filterHTMLFromLanguage( fetchingStatus.data.workspace )[ language ]
        const generalLanguageContent = filterHTMLFromLanguage( fetchingStatus.data.general )[ language ]
    
        const parsedWorkspaceSections = parseHTMLTagContent( workspaceLanguageContent, sectionTagsElement )
        const parsedGeneralSection = parseHTMLTagContent( generalLanguageContent, sectionTagsElement )    

        const languagesAvailables = getLanguagesAvailables( fetchingStatus.data )

        return (
            <div className="terms-and-conditions">
                <div className="nav-bar">
    
                    {
                        languagesAvailables.length <= 1 ||
                        ( languagesAvailables.indexOf( 'es_AR' ) > -1 && languagesAvailables.indexOf( 'es_ES' ) && languagesAvailables.length === 2 )
                        ?
                        null
                        :
                        <div className="languages">
                            <div className="title">{ t( 'Languages' ) }</div>
                            <div className="options">
                                { languagesAvailables.indexOf( 'es_AR' ) > -1 || languagesAvailables.indexOf( 'es_ES' ) > -1 ? <div className={ `option ${ language === 'es_ES' || language === 'es_AR' ? 'selected' : '' }` } onClick={ () => { changeLocale( 'es_AR' ) } }> { t( 'Spanish' ) } </div> : null }
                                { languagesAvailables.indexOf( 'en_US' ) > -1 ? <div className={ `option ${ language === 'en_US' ? 'selected' : '' }` } onClick={ () => { changeLocale( 'en_US' ) } }> { t( 'English' ) } </div> : null }
                                { languagesAvailables.indexOf( 'pt_BR' ) > -1 ? <div className={ `option ${ language === 'pt_BR' ? 'selected' : '' }` } onClick={ () => { changeLocale( 'pt_BR' ) } }> { t( 'Portugues' ) } </div> : null }
                            </div>
                        </div>
                    }
    
                    <div className="index">
                        {
                            fetchingStatus.data.workspace
                            ?
                            <div className="block">
                                <div className="title"> { t( 'Terms of service' ) } </div>
                                <div className="items">
                                    {
                                        parsedWorkspaceSections
                                            .map( ( item, index ) => <div 
                                                                        className={ `item ${ sectionIndexSelected === index ? 'selected' : '' }` } 
                                                                        key={ index } 
                                                                        onClick={ () => { scrollToItem( index ) } }>
                                                                            { item }
                                                                    </div> )
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="block">
                            <div className="title"> { t( 'Platform Terms' ) } </div>
                            <div className="items">
                                {
                                    parsedGeneralSection
                                        .map( ( item, index ) => <div 
                                                                    className={ `item ${ sectionIndexSelected === parsedWorkspaceSections.length + index ? 'selected' : '' }` } 
                                                                    key={ index } 
                                                                    onClick={ () => { scrollToItem( parsedWorkspaceSections.length + index ) } }>
                                                                        { item }
                                                                </div> )
                                }
                            </div>
                        </div>
                    </div>
    
                </div>
                <div className="content" ref={ contentRef } onScroll={ scrollFx }></div>
            </div>
        )
    }

}

export default withNamespaces()(TermsAndConditions)


// FUNCION PARA PARSEAR CONTENIDO DEL HTML STRINGIFIEADO
// INPUT => STRING HTML
//          TAG H2 QUE SPLITEARA ( EJ. H1 )
// RETORNA ARRAY CON LOS CONTENIDOS ENCONTRADOS ( EJ. DENTRO DE LOS H1 )            
function parseHTMLTagContent( content, tag ){

    if ( !content || !tag ) return []

    const stringRegexp = new RegExp( `<${ tag } [^>]+>(.*?)<\/${ tag }>`, "g" )
    const stringRegexpWithoutAttr = new RegExp( `<${ tag }>(.*?)<\/${ tag }>`, "g" )

    const match = content.match( stringRegexp ) || content.match( stringRegexpWithoutAttr )

    if ( !match ) return []
        
    return match.map( val => {

            const openStringRegexp = new RegExp( `<\/?${ tag }>`, "g" )
            const closeStringRegexp = new RegExp( `<${ tag } [^>]+>(.*?)`, "g" )

            return val
                    .replace( openStringRegexp, '' )
                    .replace( closeStringRegexp, '' )

        } );

}

function filterHTMLFromLanguage( content ) {

    const response = {
        es_AR: null,
        es_ES: null,
        en_US: null,
        pt_BR: null
    }
    const languages = parseHTMLTagContent( content, languageTagsElement )
    
    languages.map( ( language, index ) => {
        
        const languageContent = filterHTMLFromLanguageIndex( content, index )

        if ( language === 'Español' ){

            response.es_AR = languageContent
            response.es_ES = languageContent

        } else if ( language === 'English' ){

            response.en_US = languageContent

        } else if ( language === 'Português' ){

            response.pt_BR = languageContent

        }

    } )

    return response
}

function filterHTMLFromLanguageIndex( content, index, languageTag = languageTagsElement ){

    return content
            .split( '<' + languageTag + '>' )[ index + 1 ]
            .split( '</' + languageTag + '>' )[ 1 ]

}

function getLanguagesAvailables( content ){

    const parsedWorkspaceContent = filterHTMLFromLanguage( content.workspace )
    const parsedGeneralContent = filterHTMLFromLanguage( content.general )

    const languagesAvailables = []
    Object.keys( parsedWorkspaceContent ).map( l => {

        if ( parsedWorkspaceContent[ l ] && languagesAvailables.indexOf( l ) === -1 ){
            languagesAvailables.push( l )
        }
    } )

    Object.keys( parsedGeneralContent ).map( l => {

        if ( parsedGeneralContent[ l ] && languagesAvailables.indexOf( l ) === -1 ){
            languagesAvailables.push( l )
        }
    } )

    return languagesAvailables

}
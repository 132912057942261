import { buildQueryString } from '../constants/fx';
import Api from './Api';

const queryString = buildQueryString()

const registerService = {

  register: ( jsonData ) => {

    return Api.post(`/users?${queryString}`, jsonData, { 
      headers: { 
      'content-type': 'application/json' 
    },
    withCredentials: true
   } )
  },
  getUserDataByToken: ( token ) => {

    return Api.get('/invitations/data?invitation_token=' + token, { 
      headers: { 
      'content-type': 'application/json' 
    },
    withCredentials: true
   } )
  }

}

export default registerService

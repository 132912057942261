import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { withNamespaces, Trans } from 'react-i18next';
import { resources } from '../../../i18n/i18n'
import registerService from '../../../services/Register'
import Title from '../../components/Title'
import { regExpEmail } from '../../../constants/constants';

import toastr from 'toastr'
import env from '../../../environment/environment'
import amplitude from 'amplitude-js'
import dompurify from 'dompurify'
import { premiumCss } from '../../../services/PremiumLogin';
import './register.css'
import { buildQueryString, getQueryParams, parseCountryFromLanguage } from '../../../constants/fx';
import { COUNTRIES, COUNTRY_CUSTOM_DATA_ID, INVITATION_CODE_CUSTOM_DATA_ID } from '../../../constants/constants';
import CustomData from './components/CustomData';
import FieldInfo from './components/FieldInfo';
import TermsAndConditions from '../../components/TermsAndConditions';
import tenancyService from '../../../services/tenancy';
import loginService from '../../../services/Login';

const Register = props => {

  // MODAL DE TERMINOS Y CONDICIONES
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false)
  const [emailVerificationVisible, setEmailVerificationVisible] = useState(false)

  const [existingUserModalVisible, setExistingUserModalVisible] = useState(false)
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: '' });
  const [formdata, setFormdata] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    mgm_token: '',
    desktop: props.workspace && props.workspace.subdomain ? props.workspace.subdomain : undefined,
    token: props.token ? props.token : undefined,
    eula: false,
    locale: props.locale,
    signup_invitation_code: props.workspace && props.workspace.signup_invitation_code_config_id ? props.workspace.signup_invitation_code_config_id : undefined
  })

  const location = useLocation()
  const query = getQueryParams(location.search);

  useEffect(() => {
    setFormdata({
      ...formdata,
      locale: props.locale
    })
  }, [props.locale])

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  // CONTROLADOR AL CAMBIAR ALGUN CAMPO
  const handleChangeValue = (prop, value, ev) => {
    if (ev && value.includes(" ")) {
      value = ev.target.value.replace(/\s/g, "");
    }
    if (prop === 'password') {
      validatePassword(value)
    }
    if (regExpEmail.test(value)) {
      setErrors({ ...errors, email: "" })
    }
    const newformdata = { ...formdata }
    newformdata[prop] = value

    setFormdata(newformdata)

  }

  const validateEmail = (email) => {
    if (email) {
      if (!regExpEmail.test(email)) {
        setErrors({ ...errors, email: props.t('Invalid format') })
      } else {
        setErrors({ ...errors, email: '' })
      }
    } else {
      setErrors({ ...errors, email: '' })
    }
  }

  const validatePassword = (value) => {
    if (value.length < 8) {
      setErrors({ ...errors, password: props.t('The password must be at least 8 characters') })
    } else {
      setErrors({ ...errors, password: '' })
    }
  }

  // FX DE REGISTRO
  const register = () => {

    setLoading(true)

    const data = { ...formdata, password_check: formdata.password }

    let [validateData, camposFallidos] = validateAllFields(data, props.workspace)
    if (validateData === 4) {
      var acepta = window.confirm(props.t('agree_eula'));
      data.eula = acepta;
      if (acepta) {
        validateData = null;
        setFormdata({ ...formdata, eula: true });
      }
    }

    if (validateData) {
      const errorMsg = handleError(validateData, props.t)
      amplitude.getInstance().logEvent('Sign Up Error', { source: 'Frontend', reason: errorMsg + " " + camposFallidos.toString() });
      setLoading(false);
      return
    }


    registerService.register(data)
      .then(res => {

        try {

          window.dataLayer.push({ 'event': 'AuraviewAction', 'eventCategory': 'Sign Up', 'eventAction': 'click', 'eventLabel': 'Finished', 'eventValue': 1, 'eventNonInteraction': true, 'properties': { 'Language': props.locale, 'Source': 'Manual', place: 'accounts' } });

        } catch (error) {
          console.log('Error registrando eventos.')
        }

        if (props.checkout) {

          loginService
            .validateCheckout({ payment: query.get('payment'), locale: query.get('locale') })
            .then(res => {

              if (res.data.redirect_url) window.location.href = res.data.redirect_url

            })
            .catch(err => {
              tenancyService.redirectToAuraview()
            })

        } else {

          tenancyService.redirectToAuraview(props?.workspace?.subdomain, props.deeplink, props.startFeature)

        }

      })
      .catch(err => {
        const code = err.response.data.code

        setLoading(false);

        if (code === "EMAIL_VERIF_PENDING") {
          setEmailVerificationVisible(true)
          return
        }
        if (code === 2) {
          setExistingUserModalVisible(true)
          return
        }

        const errorMsg = handleError(code, props.t)
        amplitude.getInstance().logEvent('Sign Up Error', { source: 'Backend', reason: errorMsg });
      })

  }



  //FX REGISTRO CON GOOGLE 
  // API_URL/api/auth/integrations/google ? context=CONTEXT & locale=LOCALE & subdomain=SUBDOMAIN
  const registerGoogle = () => {

    window.dataLayer.push({ 'event': 'AuraviewAction', 'eventCategory': 'Sign Up SSO', 'eventAction': 'click', 'eventLabel': 'Clicked', 'eventValue': 1, 'eventNonInteraction': true, 'properties': { 'Language': props.locale, 'Source': 'Google SSO', place: 'accounts' } });


    const URL_API = tenancyService.getAPICurrentBaseURL();
    const queryString = buildQueryString();
    const mgm = new URLSearchParams(location.search).get('mgm');

    let url = `${URL_API}/auth/integrations/google?context=signup${props.checkout ? (`&redirect_url=${window.location.origin}/checkout?payment=${query.get('payment')}`) : ''}&locale=${props.locale}`;

    if (props.workspace && props.workspace.subdomain) {
      url += "&subdomain=" + (props.workspace.subdomain ? props.workspace.subdomain : '');
    }

    if (mgm && mgm !== null) {
      url += "&mgm=" + mgm;
    }

    url += '&' + queryString.toString();
    window.location.href = url;
  };



  // HOOK INICIAL 
  // 1 - AMPLITUDE
  // 2 - INICIALIZACION DE DATOS EN CAMPOS POR URL
  useEffect(() => {

    amplitude.getInstance().init(env.amplitude_key, null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      unsetParamsReferrerOnNewSession: true
    })

    window.dataLayer.push({ 'event': 'AuraviewAction', 'eventCategory': 'Sign Up', 'eventAction': 'click', 'eventLabel': 'Started', 'eventValue': 1, 'eventNonInteraction': true, 'properties': { 'Language': props.locale, 'Source': 'Manual', place: 'accounts' } });

    // INICIALIZO DATOS DEL USUARIO CON 
    // 1 - DATOS DEL TOKEN SI ES INVITACION
    // 2 - URL 
    const newFormData = {
      ...formdata,
      email: props.initialUser && props.initialUser.email ? props.initialUser.email : query.get('email'),
      first_name: props.initialUser && props.initialUser.firstname ? props.initialUser.firstname : query.get('firstname'),
      last_name: props.initialUser && props.initialUser.lastname ? props.initialUser.lastname : query.get('lastname'),
      mgm_token: query.get('mgm')
    }
    setFormdata(newFormData)

    if (query.get('s') === 'cropchain') {
      return window.location.href = window.location.href.replace('s=cropchain', 's=')
    }

  }, [])

  // HOOK AL RECIBIR LA INFORMACION DE /EXTERNALDATA REFERIDA AL ESPACIO
  useEffect(() => {

    if (props.workspace && props.workspace.data && props.workspace.data.custom_data) {

      // INICIALIZO DATOS DEL USUARIO CON 
      // 1 - DATOS DEL TOKEN SI ES INVITACION
      // 2 - URL 
      const formDataCustom = {
        ...formdata,
        email: props.initialUser && props.initialUser.email ? props.initialUser.email : query.get('email'),
        first_name: props.initialUser && props.initialUser.firstname ? props.initialUser.firstname : query.get('firstname'),
        last_name: props.initialUser && props.initialUser.lastname ? props.initialUser.lastname : query.get('lastname'),
        mgm_token: query.get('mgm')
      }
      // ITERO CADA CUSTOM DATO 
      // INICIALIZO EL PAIS SEGUN EL LENGUAJE SELECCIONADO ACTUALMENTE
      // INICIALIZO EL RESTO DE LOS PARAMETROS POR SI VIENEN POR URL CON EL FORMATO "cd<X>=YYYY"
      props.workspace.data.custom_data.map(field => {

        let initValue = ''
        if (field.id === COUNTRY_CUSTOM_DATA_ID) {

          const validLanguage = Object.keys(resources).find(dato => dato.split("_")[0] === navigator.language.split("-")[0])
          const initLanguage = validLanguage ? parseCountryFromLanguage(validLanguage) : 'Argentina'

          initValue = initLanguage

          if (COUNTRIES.includes(getQueryParams(location.search).get('cd' + COUNTRY_CUSTOM_DATA_ID))) {

            initValue = getQueryParams(location.search).get('cd' + COUNTRY_CUSTOM_DATA_ID)
          }

        } else {

          if (getQueryParams(location.search).get('cd' + field.id)) initValue = dompurify.sanitize(getQueryParams(location.search).get('cd' + field.id))
        }

        formDataCustom[field.name] = {
          id: field.id,
          value: initValue,
        }

        // BLOQUEO LOS DATOS DE INVITACIONES CUSTOM SI VIENEN POR URL
        if (getQueryParams(location.search).get('cd' + INVITATION_CODE_CUSTOM_DATA_ID) && field.id === INVITATION_CODE_CUSTOM_DATA_ID) {
          formDataCustom[field.name]['disabled'] = true
        }

      })

      setFormdata(formDataCustom)
    }

  }, [props?.workspace?.data?.custom_data])

  let customFields
  if (props.workspace && props.workspace.data && props.workspace.data.custom_data) {

    customFields = props.workspace.data.custom_data.map(field => {

      return <CustomData
        key={field.id}
        id={field.id}
        name={field.name}
        type={field.type}
        items={field.items}
        required={field.mandatory}
        min={field.min}
        max={field.max}
        step={field.step}
        min_length={field.min_length}
        max_length={field.max_length}
        placeholder={field.placeholder}
        formdata={formdata}
        query={getQueryParams(location.search)}
        handleChangeValue={handleChangeValue}
        t={props.t}

      />
    })
  }

  return (
    <div className="registerContainer">
      <Title workspace={props.workspace} />
      {
        props.workspace && props.workspace.subdomain ?
          null
          :
          <React.Fragment>
            <span className='welcome'>{props.t('Sign Up')} </span>
            <div onClick={registerGoogle} className="google-singup-button">
              <span className='svg'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg></span>
              <span>{props.t('Sign Up With Google')}</span>
            </div>
            <div className='separator'>
              <svg width="147" height="2" viewBox="0 0 147 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="147" height="1" rx="0.5" fill="#828282" />
              </svg>
              o
              <svg width="147" height="2" viewBox="0 0 147 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="147" height="1" rx="0.5" fill="#828282" />
              </svg>
            </div>
          </React.Fragment>
      }      
      {
        props.workspace && props.workspace.data
          ?
          (
            props.workspace.data.premium
              ?
              <div className="message">
                <Trans i18nKey="register-message-1" space={props.workspace.subdomain}>
                  Associate your existing account with {{ space: props.workspace.subdomain }} below. A new one will be generated in case you don't have it:
                </Trans>
              </div>
              :
              <div className="message">
                <Trans i18nKey="register-message-2" space={props.workspace.subdomain}>
                  Associate your existing account with the {{ space: props.workspace.subdomain }} space below. A new one will be generated in case you don't have it:
                </Trans>
              </div>
          )
          :
          null
      }

      <div className="form">

        <div className="field-wrapper input required premium-input">
          <input id="register-name" value={formdata.first_name} onChange={ev => { handleChangeValue('first_name', ev.target.value) }} className="form-control premium-input" placeholder=" " name="first_name'" />
          <span className="placeholder">{props.t('Name')}</span>
        </div>

        <div className="field-wrapper input required premium-input">
          <input id="register-lastname" value={formdata.last_name} onChange={ev => { handleChangeValue('last_name', ev.target.value) }} className="form-control premium-input" placeholder=" " name="last_name" />
          <span className="placeholder">{props.t('Lastname')}</span>
        </div>

        <div className="field-wrapper input required premium-input">
          <input id="register-email" disabled={props.initialUser?.email && props.initialUser?.email != ""} value={formdata.email} onChange={ev => { handleChangeValue('email', ev.target.value, ev) }} className={`form-control ${errors.email ? 'error' : 'premium-input'}`} placeholder=" " onBlur={(ev) => validateEmail(ev.target.value)} name="email" />
          <span className="placeholder">{props.t('Email')}</span>
          {errors.email && <b className="error-msg">{errors.email}</b>}
        </div>

        {customFields}

        {
          props?.workspace?.data?.signup_invitation_code_config_id && !props.token
            ?
            <div className="field-wrapper input premium-input">
              <input id="register-invitation-code" type="text" value={formdata.signup_invitation_code} onChange={ev => { handleChangeValue('signup_invitation_code', ev.target.value) }} className="form-control premium-input" placeholder=" " name="signup_invitation_code" />
              <span className="placeholder ">{props.t('Código de Invitación')}</span>
              <FieldInfo messages={props.workspace.data.messages.signup_invitation_code} locale={props.locale} className="" />
            </div>
            :
            null
        }

        <div className="field-wrapper input required premium-input">
          <input id="register-password" type={passwordVisible ? 'text' : 'password'} value={formdata.password} onChange={ev => { handleChangeValue('password', ev.target.value, ev) }} name="password" className="form-control premium-input" placeholder=" " />
          <span className="placeholder">{props.t('Password')}</span>
          <span toggle="#password" className={!passwordVisible ? "toggle-password" : "toggle-password-closed"} title="ver contraseña" onClick={handlePasswordVisibility}></span>
          {errors.password && <b className="error-msg">{errors.password}</b>}
        </div>

        <div className="tyc checkbox required ">
          <Checkbox onChange={ev => { handleChangeValue('eula', ev.target.checked) }} checked={formdata.eula} id="terms" />
          <label htmlFor="terms" id="terms" className='action-go'> {props.t('I accept the')} </label>
          <span onClick={() => { setModalVisible(true) }} className='action-font'>{props.t('terms and conditions')}</span>
        </div>

      </div>
      <div className="actions">

        <Button type="primary" block size="large" onClick={register} loading={loading} className="premiun-button-action">
          <span className='action-go' >{!loading ? props.t('Sign Up') : ''}</span>
        </Button>

        <div className="login" >
          {props.t('Already signed up? Please')} <Link to={props.checkout ? `/checkout${location.search}` : `/login${location.search}`}> <span className='action-font'>{props.t('log in')}</span> </Link>
        </div>
      </div>

      <Modal
        title={<div className="custom-title" style={props?.workspace?.data?.look_and_feel?.dark?.modals?.title ? { background: props?.workspace?.data?.look_and_feel?.dark?.modals?.title.background, color: props?.workspace?.data?.look_and_feel?.dark?.modals?.title.color } : {}}>{props.t('Email verification required')}</div>}
        wrapClassName="emailValidation"
        visible={emailVerificationVisible}
        footer={false}
        onCancel={() => { setEmailVerificationVisible(false); window.location.href = "/login" }}>
        <div className="container">
          <div className="title">
            <p>
              {props.t(
                'Por tu seguridad, necesitamos que valides tu e-mail ingresando al link que enviamos a'
              )}
              :<span className='email-validation__email'>{formdata?.email}</span>
            </p>
            <p>
              {props.t('Si no lo encontrás, revisá la carpeta de spam o correo no deseado')}
              .
            </p>
            <p>
              {props.t(
                'Si tu e-mail es incorrecto o estás teniendo problemas para validar tu cuenta'
              )}
              :
              <a
                style={{ display: 'block' }}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'AuraviewAction',
                    eventCategory: 'Pop-up',
                    eventAction: 'view',
                    eventLabel: 'Clicked',
                    eventValue: 1,
                    eventNonInteraction: true,
                    properties: {
                      name: 'Email Verification',
                      source: 'contacta soporte',
                      action: 'view',
                      place: 'accounts'
                    }
                  })
                }}
                href='mailto:soporte@auravant.com?subject=Tengo%20problemas%20para%20validar%20mi%20correo'
              >
                {props.t('Contactá a soporte')}
              </a>
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        title={<div className="custom-title" style={props?.workspace?.data?.look_and_feel?.dark?.modals?.title ? { background: props?.workspace?.data?.look_and_feel?.dark?.modals?.title.background, color: props?.workspace?.data?.look_and_feel?.dark?.modals?.title.color } : {}}>{props.t('terms and conditions')}</div>}
        wrapClassName="terms"
        visible={modalVisible}
        footer={false}
        onCancel={() => { setModalVisible(false) }}>
        <TermsAndConditions language={formdata.locale} workspace={props?.workspace?.data} setLocale={props.setLocale} />
      </Modal>

      <Modal
        title={false}
        visible={existingUserModalVisible}
        wrapClassName="existing-user"
        footer={false}
        width={350}
        maskStyle={{ backgroundColor: 'rgba( 0,0,0,0.75 )' }}
        onCancel={() => { setExistingUserModalVisible(false) }}>

        <div className="container">
          <div className="title">
            {props.t('There is already an account for ')} <span className="email"> {formdata.email} </span>
          </div>

          <div className="actions">

            <Button className="btn-login" type="primary" block size="large">
              <Link to={props.checkout ? `/checkout${location.search}` : `/login${location.search}`}>{props.t('Log in to your account')}</Link>
            </Button>

            <Button type="text" block size="large" onClick={() => { setFormdata({ ...formdata, email: '' }); setExistingUserModalVisible(false); document.getElementById('register-email').focus() }} >
              {props.t('Enter another e-mail')}
            </Button>

          </div>
        </div>

      </Modal>
    </div>
  )
}

// MENSAJES DE ERROR
const handleError = (code, t) => {

  let msg = t('Oops! An error occurred. Please try again later')
  switch (code) {
    case 0:
      msg = t("Please complete all fields")
      break;
    case 1:
      msg = t("Invitation already used")
      break;
    case 2:
      msg = t("User already exists")
      break;
    case 3:
      msg = t("Parameter error")
      break;
    case 4:
      msg = t("Terms and conditions must be accepted")
      break;
    case 5:
      msg = t("The email does not match the invitation email")
      break;
    case 6:
      msg = t("The domain belongs to a corporate domain")
      break;
    case 8:
      msg = t("Invalid space")
      break;
    case 9:
      msg = t("No space for new users")
      break;
    case 10:
      msg = t("Hectares validation failed")
      break;
    case 11:
      msg = t("Not enough surface")
      break;
    case 12:
      msg = t("An internal user already exists in another space")
      break;
    case 13:
      msg = t("This user already exists in the space")
      break;
    case 14:
      msg = t("Account registration disabled")
      break;
    case 15:
      msg = t("The invitation code is invalid")
      break;
    case 18:
      msg = t("Please check the fields, there is incorrect data")
      break;
    case 20:
      msg = t('Oops! An error occurred. Please try again later') + 'AVTEC: 0020'
      break;
    case 21:
      msg = t('Oops! You have entered an invalid invitation code')
      break;
    case 22:
      msg = t('Oops! You have entered an invalid invitation code')
      break;
    case 23:
      msg = t('Oops! You have to enter an invitation code to complete the form')
      break;
    case 50:
      msg = t('This user already exists but your password is wrong. Please retry with the correct password')
      break;
    case 99:
      msg = t('Oops! Passwords do not match')
      break;

    default:

      break;
  }

  toastr.error(msg)

  return msg

}

// VALIDADOR DE DATOS INGRESADOS
const validateAllFields = (data, workspace) => {
  let camposFaltantes = new Array()
  let incompleteField = 0


  document.querySelectorAll('.registerContainer .form .required input[type=checkbox]').forEach(input => {
    if (!input.checked) {
      incompleteField = 4
      input.style.backgroundColor = "yellow"
    }
    else
      input.style.backgroundColor = ""
  })

  document.querySelectorAll('.registerContainer .form .required input:not([type=checkbox])').forEach(input => {
    if (input.type !== "search") {
      if (!input.value) {
        incompleteField = 18
        camposFaltantes.push(input.name)
        input.style.backgroundColor = "yellow"
      } else if (input.className.includes('error')) {
        incompleteField = 18
        input.style.backgroundColor = "yellow"
      } else
        input.style.backgroundColor = ""
    }
  })

  document.querySelectorAll(".required .ant-select-selection-item").forEach(select => {
    if (select.innerText == "") {
      incompleteField = 18
      camposFaltantes.push(select.name)
      select.style.backgroundColor = "yellow"
    }
    else
      select.style.backgroundColor = ""
  })

  if (workspace && workspace.data && !workspace.data.premium || !workspace.data) {

    if (data.password !== data.password_check) incompleteField = 99
  }

  if (workspace && workspace.data && workspace.data.signup_invitation_code_config_id === 1) {

    if (!data.token && !data.signup_invitation_code) incompleteField = 23

  }

  return [incompleteField, camposFaltantes]

}
export { Register }
export default withNamespaces()(Register)

import { buildQueryString } from '../constants/fx';
import Api from './Api';
import tenancyService from './tenancy';

const queryString = buildQueryString();
let requestBody;

const loginService = {

  
  login: ( jsonData ) => {

    requestBody = `username=${jsonData.username}&password=${jsonData.password}${jsonData.space ? '&s=' + jsonData.space : ''}${ jsonData.forcePersonal ? '&forcePersonal=true' : '' }`;
    
    return Api.post(`/auth?${queryString}`, requestBody, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'dataType': 'text',
      },
      withCredentials: true
    });
  },
  verifyCode: ( jsonData ) => {

    const requestData = {
      otp: jsonData.otp,
      redis: jsonData.redis,
    };    

    return Api.post(`/auth/2fa?${queryString}`, requestData, {
        headers: {
            'content-type': 'application/json',
            'dataType': 'text'
          },
          withCredentials: true
    } )
  },
  test: () => {
      return Api.get('/test')
  },
  validateCheckout: ( payload ) => {

    const { payment, locale } = payload || {}

    return Api.get(`/checkout/stripe/validation?payment=${ payment }${ locale ? `&locale=${ locale }` : '' }`, { withCredentials: true } )

  },
  ping: ( space ) => {
    
    return Api.get(`/ping?s=${ space }`, { withCredentials: true } );
    
  },
  // handleEmailVerification: (setShowModal) => {
  //   setShowModal(true)
  // },
  verifyEmail: (email) => {
    return fetch(tenancyService.getAPICurrentBaseURL() + '/auth/email_verification', {
      method: "POST",
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({username: email})
    } )
  }

}

export default loginService
import React from "react";
import AuraPopUp from "../AuraPopUp";
import { AuraIcon } from "../AuraIcon";

const ALERT_COLORS = {
  INFORMATION: {
    BG: "#a9d5f1",
  },
  ERROR: {
    BG: "#FC2F0226",
  },
  SUCCESS: {
    BG: "#18D37E26",
  },
  WARNING: {
    BG: "#FEB41F26",
  },
};

// interface TypeIconInterface {
//   [key: string]: "info" | "error" | "warning-green" | "warning";
// }

const typeIconInterface = {
  INFORMATION: "info",
  ERROR: "error",
  SUCCESS: "warning-green",
  WARNING: "warning",
};

// interface PopUpAlertProps {
//   title?: string;
//   description: string;
//   cancelButtonText?: string;
//   okButtonText?: string;
//   visible: boolean;
//   onOk?: () => void;
//   onCancel?: () => void;
//   onClose?: () => void;
//   onOpen?: () => void;
//   type: "information" | "error" | "success" | "warning";
//   //   cancelOnBackdropClick: boolean;
// }

export const AuraPopUpAlert = ({
  children,
  title,
  description,
  cancelButtonText,
  okButtonText,
  visible,
  onOk,
  onCancel,
  onClose,
  onOpen,
  type,
}) => {
  const sectionStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <AuraPopUp
      title={title}
      cancelButtonText={cancelButtonText}
      okButtonText={okButtonText}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      onClose={onClose}
      onOpen={onOpen}
      mainExtraStyles={{
        backgroundColor:
          ALERT_COLORS[type.toUpperCase()].BG,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <section
        style={{
          ...sectionStyle,
          flexDirection: "column",
        }}
      >
        <AuraIcon
          size="medium"
          type={
            typeIconInterface[
              type.toUpperCase()
            ]
          }
        />
        {children != null ? children : <p style={{textAlign: "center"}}>{description ?? ""}</p>}
      </section>
    </AuraPopUp>
  );
};
